import { AxiosInstance, CancelTokenSource } from 'axios';
import BaseService from './base-service';

export default class ControllerBaseService extends BaseService {
  constructor(protected controller: string, axios: AxiosInstance) {
    super(axios);
  }

  protected url(uri?: string): string {
    const suffix = uri == null ? '' : `/${uri}`;
    return `/${this.controller}${suffix}`;
  }
}

export interface IPendingRequest {
  promise: Promise<any>;
  canceler: CancelTokenSource;
  finished?: boolean;
}

export interface IPendingRequestsHashtable {
  [key: string]: IPendingRequest;
}

export class PendingRequestsHandler {
  private pendingRequests: IPendingRequestsHashtable = {};

  public insert(key: string, request: IPendingRequest) {
    request.promise.finally(() => { request.finished = true; });
    this.pendingRequests[key] = request;
  }

  public cancel(key: string) {
    if (this.canCancel(key)) {
      this.pendingRequests[key].canceler.cancel();
    }
    this.remove(key);
  }

  public remove(key: string) {
    if (this.pendingRequests[key] != null) {
      delete this.pendingRequests[key];
    }
  }

  public reset() {
    Object.keys(this.pendingRequests).forEach(this.cancel.bind(this));
  }

  private canCancel(key: string) {
    return this.pendingRequests[key] != null &&
        this.pendingRequests[key].canceler != null &&
        this.pendingRequests[key].finished !== true;
  }
}
