





































// Libraries
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
// View Models
import { IReportViewModel } from '@/view-models/reports';
// Components
import GroupedSelectedVariables from './GroupedSelectedVariables.vue';
import ClearSelectedVariablesModal from '@/components/modals/ClearSelectedVariablesModal.vue';
// Stores
import reports from '@/store/reports';
import Loading from '@/components/common/Loading.vue';

@Component({
  name: 'selected-variables',
  components: {
    GroupedSelectedVariables,
    ClearSelectedVariablesModal,
    Loading
  },
})
export default class SelectedVariables extends Vue {
  // VUE.JS Props
  @Prop({ default: false })
  public isLoadingReport: boolean;
  // Refs
  // VUEX
  public get activeReport(): IReportViewModel {
    return reports.activeReport;
  }
  public get hasSelectedVariables(): boolean {
    return reports.activeReport.reportVariableGroups && reports.activeReport.reportVariableGroups.length > 0;
  }

  // Properties
  public showClearConfirmModal: boolean = false;
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public displayClearConfirmModal(): void {
    this.showClearConfirmModal = true;
  }
  public clearAllSelectedVariables(confirmed: boolean) {
    if (confirmed) {
      reports.removeAllReportVariables();
    }
    this.showClearConfirmModal = false;
  }
  // Watchers
  // Emitters
}
