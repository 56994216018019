import { findAppBaseUrl } from '@/shared/from-parent';
import { BaseProvider } from '@/shared/providers/base-provider';
import { IConfig, Config } from '@/view-models/config';
import axios from 'axios';

class ConfigProvider extends BaseProvider<IConfig> {
  constructor() {
    super('Config');
    this.instance = new Config({
      domain: '',
      clientId: '',
      audience: '',
      redirectUriStandalone: '',
      redirectUriParent: '',
      baseApiUrl: '',
      userManagementApiUrl: ''
    });
  }

  public async init(): Promise<void> {
    const http = axios.create({
      baseURL: findAppBaseUrl(),
      timeout: 10000
    });
    const config = (await http.get('config.json')).data;
    this.instance = new Config(config);
    this.processEnvReplacements();
    this.sanitizeUrls();
  }

  private processEnvReplacements() {
    if (process.env.VUE_APP_AUTH0_DOMAIN != null) {
      this.instance.domain = process.env.VUE_APP_AUTH0_DOMAIN;
    }
    if (process.env.VUE_APP_AUTH0_CLIENT_ID != null) {
      this.instance.clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;
    }
    if (process.env.VUE_APP_AUTH0_AUDIENCE != null) {
      this.instance.audience = process.env.VUE_APP_AUTH0_AUDIENCE;
    }
    if (process.env.VUE_APP_AUTH0_REDIRECT_URI != null) {
      this.instance.redirectUri = process.env.VUE_APP_AUTH0_REDIRECT_URI;
    }
    if (process.env.VUE_APP_CUSTOMER_ASSET_REPORTING_API_BASE_URL != null) {
      this.instance.baseApiUrl = process.env.VUE_APP_CUSTOMER_ASSET_REPORTING_API_BASE_URL;
    }
    if (process.env.VUE_APP_UM_API_BASE_URL != null) {
      this.instance.userManagementApiUrl = process.env.VUE_APP_UM_API_BASE_URL;
    }
  }

  private sanitizeUrls() {
    if (this.instance.baseApiUrl.endsWith('/')) {
      this.instance.baseApiUrl = this.instance.baseApiUrl.replace(/\/$/, '');
    }
    if (this.instance.baseApiUrl.endsWith('/api')) {
      this.instance.baseApiUrl = this.instance.baseApiUrl.replace(/\/api$/, '');
    }

    if (this.instance.userManagementApiUrl.endsWith('/')) {
      this.instance.userManagementApiUrl = this.instance.userManagementApiUrl.replace(/\/$/, '');
    }
    if (this.instance.userManagementApiUrl.endsWith('/api')) {
      this.instance.userManagementApiUrl = this.instance.userManagementApiUrl.replace(/\/api$/, '');
    }
  }
}

export default new ConfigProvider();
