import { Nil } from './types';

export class TimerLock {
  private locked: boolean = false;
  private timeout: Nil<number | NodeJS.Timeout> = null;

  public get isLocked() { return this.locked; }

  constructor(private readonly msThreshold: number, startNow: boolean = false) {
    if (startNow) {
      this.reset();
    }
  }

  public unlock() {
    this.locked = false;
    this.clearTimeout();
  }

  public reset() {
    this.locked = true;
    this.clearTimeout();

    this.timeout = setTimeout(() => {
      this.locked = false;
      this.clearTimeout();
    }, this.msThreshold);
  }

  public tryAccess() {
    if (this.locked) { return false; }
    this.reset();
    return true;
  }

  public async tryProcess(func: () => Promise<void>) {
    if (this.tryAccess()) {
      await func();
    }
  }

  private clearTimeout() {
    if (this.timeout != null) {
      clearTimeout(this.timeout as NodeJS.Timeout);
    }
  }
}
