













// Libraries
import {Component, Mixins, Watch} from 'vue-property-decorator';
import { Route } from 'vue-router';
import { ParentEvents, configureEventBuses } from '@/shared/event-bus';
import { parentEventBus, appRouteBase } from '@/shared/from-parent';
import router from './router';
import i18n from '@/shared/i18n/index';
import config from '@/config';
import auth from '@/shared/auth';
import { initializeHttpClients } from '@/services/common/http';
import vuePlugins from '@/plugins';
// View Models
// Components
import Error from './components/modals/Error.vue';
import Loading from './components/common/Loading.vue';
import HeaderComponent from '@/components/HeaderComponent.vue';
import AppReady from './components/mixins/app-ready';
// Store
import store from '@/store/';
import appStore from '@/store/app';
import reports, { reportsVariablesFetchLock } from '@/store/reports';
import reportVariablesTree from './store/report-variables-tree';
import user from '@/store/user';

// Initializing the app global services/configs/utilities
// There is an isAppInitialized property on the app store
// to know when the app is ready
(async function initializeApp() {
  appStore.setIsAppInitialized(false);

  // Config
  await config.init();
  // Auth
  await auth.init();
  // Http Client
  initializeHttpClients();
  // Router
  router.init();
  // Vue Plugins
  vuePlugins();
  // Events Buses
  configureEventBuses();


  appStore.setIsAppInitialized(true);
})();

@Component({
  name: 'app',
  components: {
    Error,
    Loading,
    HeaderComponent,
  },
  store,
  router: router.instance,
  i18n,
})
export default class App extends Mixins(AppReady) {
  // VUE.JS Props
  // Refs
  // VUEX
  public get isLoading() {
    return appStore.isLoading || !appStore.isAppInitialized;
  }

  public get activeCustomerKey() {
    return appStore.activeCustomerKey;
  }

  public mounted() {
    this.initialRoute();
    parentEventBus()?.$on(ParentEvents.RouteChange, this.handleRouteChange);
    parentEventBus()?.$on(ParentEvents.ActiveCustomerChanged, this.handleActiveCustomerChange);
  }

  public async ready() {
    user.getUser().then(async () => {
      await this.getReportsAndVariables();
    });

   }

  private async getReportsAndVariables() {
    await reports.fetchReportsList();
  }

  private deactivated() {
    // Get rid of old vue instance
    this.$destroy();
    // Used when integrated into parent app
    appStore.setIsLoading(true);
    reportVariablesTree.setLoadingReportVariablesTrees(false);
    reportVariablesTree.setReportVariablesTreesLoaded(false);
    parentEventBus()?.$off(ParentEvents.RouteChange, this.handleRouteChange);
    parentEventBus()?.$off(ParentEvents.ActiveCustomerChanged, this.handleActiveCustomerChange);
  }

  // Private Methods
  private initialRoute() {
    const base = appRouteBase();
    const path = base.slice(0, base.length - 1) + this.$route.path;
    const route = Object.assign({}, this.$route, { path });
    this.handleRouteChange(route);
  }
  private handleRouteChange(parentRoute: Route) {
    if (parentRoute.path.startsWith(appRouteBase())) {
      appStore.setIsLoading(false);
      let relativeRoute = parentRoute.path.replace(appRouteBase(), '/');
      relativeRoute = relativeRoute.replace(/\/\//g, '/');
      if (relativeRoute !== this.$route.path) {
        this.$router.push(relativeRoute);
      }
    }
  }
  private handleActiveCustomerChange(key: string) {
    appStore.setActiveCustomerKey(key);
  }

  // Watchers
  @Watch('activeCustomerKey')
  public async activeCustomerKeyChanged() {
    reportsVariablesFetchLock.unlock();
    reportVariablesTree.setReportVariablesTreesLoaded(false);
    await user.getUser();
    await reports.fetchReportsList();
  }
  // Emitters
}
