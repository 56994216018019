import VueI18n from 'vue-i18n';
import HelperMethods from '@/shared/helper-methods';
import { IDateRange } from '@/view-models/report-time-model';

export class HighchartFormatHelper {
  protected vueI18n: VueI18n;

  constructor(vueI18n: VueI18n) {
    this.vueI18n = vueI18n;
  }

  public abbreviateLargeNumber(value: number): string {
    const translation = findLargeNumberAbbreviation(value);
    if (translation.key === null) {
      return value.toString();
    }
    return this.vueI18n.t(`global.largeNumberAbbreviations.${translation.key}`,
    { value: translation.value }).toString();
  }

  public dateNumeric(dateValue: Date, dateRange: IDateRange): string {
    const formatType: string = resolveDateFormatString(dateRange, 'numericDateShortened');
    return this.vueI18n.d(dateValue, formatType);
  }

  public date(dateValue: Date, dateRange: IDateRange): string {
    const formatType: string = resolveDateFormatString(dateRange, 'dateShortenedWithYear');
    return this.vueI18n.d(dateValue, formatType);
  }
}

 function resolveDateFormatString(dateRange: IDateRange, format: string): string {
  const fromDate = HelperMethods.asMoment(dateRange.fromDate);
  const toDate = HelperMethods.asMoment(dateRange.toDate);
  const differentDay = toDate != null && fromDate != null && !toDate.isSame(fromDate, 'day');
  return differentDay ? format : 'time';
}

export function findLargeNumberAbbreviation(value: number) {
  let key = null;

  if (value >= 1000000000000 || value <= -1000000000000) {
    value = value / 1000000000000;
    key = 'trillion';
  } else if (value >= 1000000000 || value <= -1000000000) {
    value = value / 1000000000;
    key = 'billion';
  } else if (value >= 1000000 || value <= -1000000) {
    value = value / 1000000;
    key = 'million';
  } else if (value >= 1000 || value <= -1000) {
    value = value / 1000;
    key = 'thousand';
  }

  return { key, value };
}
