import { AxiosInstance } from 'axios';
import { AccessSettingsEnum } from '@/enums/variables';
import ControllerBaseService from '@/services/common/controller-base-service';
import { http } from '@/services/common/http';
import { IVariableDataRequest, IVariableDataResponse } from '@/view-models/variable-data-view-models';

export default class VariableDataService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('variables', axios);
    }

    public static factory(): VariableDataService {
        return new VariableDataService(http.instance);
    }

    /**
     * Get chart data for requested variables within supplied date range
     * @param variableDataRequest IVariableDataRequest
     * @returns {Promise<Array<IVariableDataResponse>>}
     */
    public getVariablesData(variableDataRequest: IVariableDataRequest): Promise<Array<IVariableDataResponse>> {
        const headers = { 'api-version': '2.0' };
        return this.httpPost<Array<IVariableDataResponse>>(
            `data?accessSetting=${AccessSettingsEnum.Reports}`,
            variableDataRequest,
            null,
            headers
        );
    }
}
