export enum UnitOfMeasurementEnum {
  // #region Generic Units
  Default = 'Default',
  Unknown = 'Unknown',
  Percent = 'Percent',
  Bool = 'Bool',
  Float = 'Float',
  Fraction = 'Fraction',
  String = 'String',
  // #endregion

  // #region Pressure Units
  Pa = 'Pa',
  psi = 'psi',
  psig = 'psig',
  psia = 'psia',
  inH2O = 'inH2O',
  mmH2O = 'mmH2O',
  inHg = 'inHg',
  bar = 'bar',
  barg = 'barg',
  mbar = 'mbar',
  mbarg = 'mbarg',
  kgcm2 = 'kgcm2',
  // #endregion

  // #region Concentration Units
  ppm = 'ppm',
  percentmol = 'percentmol',
  mgNm3 = 'mgNm3',
  // #endregion

  // #region Temperature Units
  degF = 'degF',
  degC = 'degC',
  degR = 'degR',
  degK = 'degK',
  // #endregion

  // #region Distance Units
  ft = 'ft',
  in = 'in',
  m = 'm',
  mm = 'mm',
  // #endregion

  // #region Flow Rate Units
  kgs = 'kgs',
  kghr = 'kghr',
  lbms = 'lbms',
  lbmhr = 'lbmhr',
  klbmhr = 'klbmhr',
  lbs = 'lbs',
  // #endregion

  // #region Duty (Energy) Units
  W = 'W',
  MW = 'MW',
  Gcalhr = 'Gcalhr',
  MMBTUhr = 'MMBTUhr',
  Mmkcalhr = 'Mmkcalhr',
  BTUh = 'BTUh',
  // #endregion

  // #region FuelPropertyEnergy
  BTUscf = 'BTUscf',
  kCalkg = 'kCalkg',
  Jkg = 'Jkg',
  BTUlbm = 'BTUlbm',
  // #endregion

  // #region Area Units
  sqft = 'sqft',
  sqin = 'sqin',
  sqm = 'sqm',
  sqcm = 'sqcm',
  // #endregion

  // #region O2Mole Fraction
  DRY = 'DRY', // O2MoleFraction
  WET = 'WET', // O2MoleFraction
  // #endregion
}
