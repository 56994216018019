































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
// View Models
// Components
// Services
// Store
import reportVariablesTree from '@/store/report-variables-tree';
import { IAssignmentTreeNodeViewModel } from '@/view-models/report-variables-tree/report-variable-node';
import { alphabeticSorter } from '@/shared/array-utils';
import { parentEventBus } from '@/shared/from-parent';
import { ParentEvents } from '@/shared/event-bus';
import { ISelectItem } from '@/components/types/select-box';


@Component({
name: 'report-variables-tree-header',
  components: {SelectDropdown}
})
export default class ReportVariablesTreeHeader extends Vue {
  // VUE.JS Props

  // VUEX
  public get sites(): IAssignmentTreeNodeViewModel[] {
    return reportVariablesTree.sites ?? [];
  }
  public get assets(): IAssignmentTreeNodeViewModel[] {
    const assets = this.selectedSite
        ? reportVariablesTree.assets.filter((asset) => asset.customerSiteKey === this.selectedSite.key)
        : [];
    assets.sort(alphabeticSorter<IAssignmentTreeNodeViewModel>((node) => node.name));
    return assets;
  }
  public get siteOptions(): Array<ISelectItem<IAssignmentTreeNodeViewModel>> {
    const options = this.sites.map((site) => ({
      text: site.name,
      value: site
    }));
    if (this.selectedSite == null) {
      options.unshift({
        text: this.$t('settings.treeHeader.selectSite').toString(),
        value: null
      });
    }
    return options;
  }
  public get assetOptions(): Array<ISelectItem<IAssignmentTreeNodeViewModel>> {
    const options = this.assets.map((asset) => ({
      text: asset.name,
      value: asset
    }));
    if (this.selectedAsset == null) {
      options.unshift({
        text: this.$t('settings.treeHeader.selectAsset').toString(),
        value: null
      });
    }
    return options;
  }
  // Properties
  // Fields
  // Getters
  public get hasSites(): boolean {
    return this.sites.length > 0;
  }
  public get selectedAsset(): IAssignmentTreeNodeViewModel {
    return reportVariablesTree.selectedAsset;
  }
  public get selectedSite(): IAssignmentTreeNodeViewModel {
    return reportVariablesTree.selectedSite;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    parentEventBus()?.$on(ParentEvents.ActiveCustomerChanged, this.resetSiteAssetSelection);
  }
  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    parentEventBus()?.$off(ParentEvents.ActiveCustomerChanged, this.resetSiteAssetSelection);
  }
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private resetSiteAssetSelection() {
    reportVariablesTree.setSelectedSite(null);
    reportVariablesTree.setSelectedAsset(null);
    reportVariablesTree.setRootNode(null);
  }
  // Event Methods
  public selectSite(val: IAssignmentTreeNodeViewModel) {
    reportVariablesTree.setSelectedSite(val);
    reportVariablesTree.setSelectedAsset(null);
    reportVariablesTree.setRootNode(null);
  }

  public selectAsset(val: IAssignmentTreeNodeViewModel) {
    reportVariablesTree.setSelectedAsset(val);
    reportVariablesTree.fetchReportVariablesTree();
  }
  // Watchers
  // Emitters
}
