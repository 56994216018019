





















































// Libraries
import { Vue, Component } from 'vue-property-decorator';
// View Models
// Components
import ReportChart from '@/components/reportVisuals/ReportChart.vue';
import ReportViewActionsBar from '@/components/ReportViewActionsBar.vue';
import ReportVariableControls from '@/components/reportVariables/ReportVariableControls.vue';
import Loading from '@/components/common/Loading.vue';
// Stores
import reports from '@/store/reports';
import {
  IReportVariableViewModel,
  IReport,
  IReportViewModel,
  IReportVariableUomModel
} from '@/view-models/reports';
// Utilities
import { alphabeticSorter } from '@/shared/array-utils';
import user from '@/store/user';

@Component({
  name: 'report-view',
  components: {
    ReportChart,
    ReportViewActionsBar,
    ReportVariableControls,
    Loading
  }
})
export default class ReportViewPage extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX

  public requestVariablesData() {
    reports.requestVariablesData({ key: this.currentMeasurementSystem, changed: false });
  }
  public get report(): IReport {
    return reports.activeReport;
  }
  public get currentMeasurementSystem(): string {
    return reports.currentMeasurementSystem;
  }
  // Properties
  public loadingReport: boolean = true;
  private expandedRow: string = '';
  // Fields
  // Getters
  public get editableVariables(): IReportVariableViewModel[] {
    return reports.activeReport.reportVariables.sort(alphabeticSorter((s) => s.displayName));
  }
  public get activeReport(): IReportViewModel {
    return reports.activeReport;
  }
  public async refresh() {
    // EventBus.$emit(Events.REPORT_SHIFT_RESET);
    // (this.$refs.reportChart as ReportChart).refreshChart();
  }
  private isExpanded(name: string): boolean {
    return this.expandedRow === name;
  }
  private expand(name: string): void {
    if (this.expandedRow === name) {
      this.expandedRow = '';
      return;
    }
    this.expandedRow = name;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  public async beforeMount(): Promise<void> {
    reports.setSelectedVariableCount(0);
    if (!user.measurementSystemOptions?.length) {
      const currentUser = await user.getUser();
      await user.getMeasurementSystemOptions(currentUser.activeCustomerKey);
      reports.setCurrentMeasurementSystem(user.preferredMeasurementSystem);
    }
    this.loadingReport = true;
    const reportKey = this.$route.params.key;
    if (reportKey && reports.activeReport.key !== reportKey) {
      await reports.loadReportById(reportKey);
    }
    reports.setSkipCounter(0);
    await reports.requestVariablesData({ key: this.currentMeasurementSystem, changed: false });

    reports.setSelectedVariableCount(this.activeReport?.reportVariables?.length);
    this.loadingReport = false;
  }
  // mounted(): Promise<void> {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public async updateUOM(reportUom: IReportVariableUomModel) {
    await reports.requestVariableData({ uomModel: reportUom, systemKey: this.currentMeasurementSystem });
  }
  // Event Methods
  // Watchers
  // Emitters
}
