var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-page"},[_c('div',{staticClass:"car-content"},[_c('div',{staticClass:"car-variables-section"},[_c('div',{staticClass:"car-variables-vertical-column"},[(_vm.isVariableSelectionActive)?_c('img',{attrs:{"src":require("@/assets/icons/Checkbox/Color.png")}}):_c('img',{attrs:{"src":require("@/assets/icons/Checkbox/CircleUnselected.svg")}}),_c('div',{staticClass:"car-variables-vertical-line"})]),_c('div',{staticClass:"car-variables-vertical-column--padding"},[_c('div',{staticClass:"car-variables-section__collapsible"},[_c('div',{staticClass:"car-variables-section__collapsible__header"},[(!_vm.isVariablesExpanded)?_c('i',{staticClass:"icon mdi mdi-24px mdi-plus-box-outline",on:{"click":_vm.expandVariables}}):_c('i',{staticClass:"icon mdi mdi-24px mdi-minus-box-outline",on:{"click":_vm.expandVariables}}),_c('h2',{staticClass:"car-variables-hr"},[_vm._v(_vm._s(_vm.$t('reports.chooseVariables')))]),_c('hr',{staticClass:"car-variables-hr"})]),_c('div',{class:[
              'car-table-container',
              {
                'car-table-container--expanded': _vm.isVariablesExpanded,
              },
              'scrollbar' ]},[_c('b-row',[_c('b-col',[_c('report-variables-tree',{attrs:{"is-loading-report":_vm.loadingReport}})],1),_c('b-col',[_c('selected-variables',{attrs:{"is-loading-report":_vm.loadingReport}})],1)],1)],1)])])]),_c('div',{staticClass:"car-variables-section"},[_c('div',{staticClass:"car-variables-vertical-column"},[_c('img',{attrs:{"src":require("@/assets/icons/Checkbox/Color.png")}})]),_c('div',{staticClass:"car-variables-vertical-column--padding"},[_c('div',{staticClass:"car-variables-section__collapsible"},[_c('div',{staticClass:"car-variables-section__collapsible__header"},[(!_vm.isTimeFrameExpanded)?_c('i',{staticClass:"icon mdi mdi-24px mdi-plus-box-outline",on:{"click":_vm.expandTimeFrame}}):_c('i',{staticClass:"icon mdi mdi-24px mdi-minus-box-outline",on:{"click":_vm.expandTimeFrame}}),_c('h2',{staticClass:"car-variables-hr"},[_vm._v(_vm._s(_vm.$t('reports.chooseTimeFrame')))]),_c('hr',{staticClass:"car-variables-hr"})]),_c('div',{class:[
              'car-table-container',
              {
                'car-table-container--expanded': _vm.isTimeFrameExpanded,
              },
              'scrollbar' ]},[_c('div',{staticClass:"car-frequency-wrapper"},[_c('span',{staticClass:"car-time-frame-sub-header"},[_vm._v(_vm._s(_vm.$t('reports.subHeader')))]),_c('report-time-frame-section')],1)])])])]),_c('div',{staticClass:"car-report-button"},[_c('b-button',{attrs:{"variant":"primary","disabled":!_vm.isDisabled},on:{"click":_vm.runReport}},[_vm._v(_vm._s(_vm.$t('reports.runReport')))])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }