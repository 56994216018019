
















import { Component, Prop, Emit, Watch, Vue } from 'vue-property-decorator';
// @ts-ignore
import flatpickr from 'flatpickr';
// @ts-ignore
import { Instance } from 'flatpickr/dist/types/instance';
import moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import HelperMethods from '@/shared/helper-methods';
// View Models
import { DateTimeInput } from '@/view-models/report-time-model';
import { Nil } from '@/shared/types';
// Components

@Component({
  name: 'date-time-picker'
})
export default class DateTimePicker extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public value: DateTimeInput;
  @Prop({ default: false })
  public wrap: boolean;
  @Prop({ default: 1000 })
  public debounce: number;
  @Prop({ default: true })
  public allowFuture: boolean;
  @Prop({ default: null })
  public maxDate: DateTimeInput;
  @Prop({ default: null })
  public minDate: DateTimeInput;
  @Prop({ default: false })
  public showClear: boolean;
  @Prop({ default: false })
  public disableUpdateSkip: boolean;
  @Prop({ default: false })
  public placeHolderText: string;
  @Prop({ default: 'app' })
  public id: string;

  public dp: Nil<Instance> = null;
  public isCleared: boolean = false;
  public elem: HTMLElement;
  public input$: Subject<DateTimeInput> = new Subject<DateTimeInput>();
  public inputSub: Subscription = null;

  protected dateFormat: string = 'm/d/Y H:i:S';
  protected altFormat: string = 'm/d/Y h:i K';
  private skipSubscriptionTrigger: boolean = false;

  private inputSubHandler(dateVal: DateTimeInput) {
    // if (this.skipSubscriptionTrigger) {
    //   this.skipSubscriptionTrigger = false;
    //   return;
    // }

    if (typeof dateVal === 'boolean') {
      if (!this.showClear) {
        // Don't allow blanks. Use the previous value
        dateVal = this.dp?.selectedDates[0];
      }
    }

    this.triggerInput(HelperMethods.asDate(dateVal));
  }

  // Fields
  // Getters
  public get valueDate(): Date {
    return HelperMethods.asDate(this.value);
  }
  public get showClearDateIcon(): boolean {
    return this.showClear && ((this.dp?.selectedDates?.length ?? 0) > 0);
  }
  public get isOpen(): boolean {
    return this.dp?.isOpen ?? false;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  public mounted(): void {
    if (this.dp) {
      return;
    }

    this.elem = (this.wrap ? this.$el.parentNode : this.$refs.input) as HTMLElement;

    flatpickr.defaultConfig.prevArrow = '<i class="mdi mdi-chevron-left"></i>';
    flatpickr.defaultConfig.nextArrow = '<i class="mdi mdi-chevron-right"></i>';

    const parentEl = this.$el.closest(`#${this.id}`) as HTMLElement;

    this.dp = flatpickr(this.elem, {
      appendTo: parentEl, // this is root element of child app
      enableTime: true,
      altFormat: this.altFormat,
      altInput: true,
      dateFormat: this.dateFormat,
      minuteIncrement: 1,
      maxDate: !this.allowFuture ? moment().add('day').toDate() : null
    });

    const calendarElement = this.dp?.calendarContainer;
    parentEl.addEventListener('click', (event: MouseEvent) => {
      const isClickInside = calendarElement.contains(event.target as HTMLElement);
      const className = (this.$refs.input as HTMLElement)?.classList[0];
      if (! className) {
        return;
      }
      const isPickerInput = (event.target as HTMLElement).classList.contains(className);

      // to close date picker on outside click
      if (!isClickInside && this.isOpen && !isPickerInput) {
        this.dp?.close();
        // the click was outside the specifiedElement, do something
      } else if (isPickerInput && this.isOpen) {
        const currentInputElement = this.$el.querySelector(`.${className}.active`);

        if (!(event.target as HTMLElement).isSameNode(currentInputElement)) {
          this.dp?.close();
        }
      }
    });

    this.updateDates();

    this.registerEvents();

    this.inputSub = this.input$
      .pipe(debounceTime(this.debounce), distinctUntilChanged())
      .subscribe(this.inputSubHandler);
  }

  public clearDate(): void {
    if (this.dp != null) {
      this.dp.clear();
      this.triggerInput(null);
    }
  }

  // beforeUpdate(): void {}
  // updated(): void {}
  public beforeDestroy(): void {
    if (this.dp != null) {
      this.dp.destroy();
      this.dp = null;
      this.elem = null;
    }
    if (this.inputSub != null) {
      this.inputSub.unsubscribe();
    }
    if (this.input$ != null) {
      this.input$.complete();
    }
  }
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private registerEvents() {
    this.dp?.config.onChange.push(this.onChange);
    this.dp?.config.onOpen.push(() => this.showEvent());
  }

  // // Event Methods

  public onChange(unused: any, dateStr: string) {
    this.input$.next(dateStr);
  }

  private updateDates() {
    this.updateMaxMinDates();

    if (this.value) {
      this.dp?.setDate(HelperMethods.asDate(this.value), true, this.dateFormat);
    }
  }
  private updateMaxMinDates() {
    if (this.dp === null) { return; }
    if (this.minDate && this.maxDate && this.minDate <= this.maxDate) {
      this.dp.config.maxDate = HelperMethods.asDate(this.maxDate);
      this.dp.config.minDate = HelperMethods.asDate(this.minDate);
    } else if (this.minDate && !this.maxDate) {
      this.dp.config.minDate = HelperMethods.asDate(this.minDate);
      this.dp.config.maxDate = !this.allowFuture ? HelperMethods.asDate(moment().add('day')) : null;
    } else if (this.maxDate && !this.minDate) {
      this.dp.config.maxDate = HelperMethods.asDate(this.maxDate);
    } else {
      this.dp.config.maxDate = !this.allowFuture ? HelperMethods.asDate(moment().add('day')) : null;
    }
  }

  // Watchers
  @Watch('value')
  public valueChanged() {
    // if (!this.showClear && !this.disableUpdateSkip) {
    //   this.skipSubscriptionTrigger = true;
    // }
    this.updateDates();
  }

  @Watch('maxDate')
  public maxChanged() {
    this.updateDates();
  }
  @Watch('minDate')
  public minChanged() {
    this.updateDates();
  }

  // Emitters
  @Emit('input')
  public triggerInput(valEmit: Date) {}
  @Emit('dp-show')
  public showEvent() {}
}
