


















































































// Libraries
import { Component, Emit, Watch } from 'vue-property-decorator';
import { Point, TitleOptions, TooltipFormatterContextObject } from 'highcharts';
import HelperMethods from '@/shared/helper-methods';
import { elementId } from '@/shared/component-utils';
import '@/shared/extensions/string.extensions.ts';
// View Models
import { IReportDataViewModel, IReportViewModel } from '@/view-models/reports';
import { DateRange, IDateRange, IReportTimeRangeSetting } from '@/view-models/report-time-model';
import { ReportRangeType, SkipDirectionsEnum } from '@/enums/report-enums';
// components
import BaseChartComponent from '@/components/base-components/BaseChartComponent';
import Loading from '@/components/common/Loading.vue';
import DateTimePicker from '@/components/shared/DateTimePicker.vue';
import TimeRangeSkipper from '@/components/shared/TimeRangeSkipper.vue';
import { eventBus } from '@/shared/event-bus';
// Stores
import reports from '@/store/reports';
import { ReportSeriesOptionsType } from '@/shared/report-helper';
import { ISelectItem } from '../types/select-box';
import { ReportVariableMergedState, ReportVariableState } from '@/enums/report-variable-state';
import user from '@/store/user';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';

@Component({
  components: {
    Loading,
    TimeRangeSkipper,
    DateTimePicker,
  },
  name: 'report-chart',
})
export default class ReportChart extends BaseChartComponent {
  // VUE.JS Props
  public reportChartId: string = 'report-chart';

  // VUEX
  public get reportTimeSettings(): IReportTimeRangeSetting {
    return reports.reportTimeSettings;
  }

  public get executedReport(): IReportViewModel {
    return reports.executedReport;
  }

  public get activeReport(): IReportViewModel {
    return reports.activeReport;
  }

  public get isFetchingReportData(): boolean {
    return reports.isFetchingReportData;
  }

  public get reportData(): Array<IReportDataViewModel> {
    return reports.reportData;
  }

  public get isShowGridLinesValue(): boolean {
    return this.executedReport.showGridLines;
  }

  public set isShowGridLinesValue(value: boolean) {
    reports.setIsGridLines(value);
  }

  public get measurementSystemOptions(): IAvailableMeasurementSystemsViewModel[] {
    return user.measurementSystemOptions;
  }

  public get options(): Array<ISelectItem<ReportRangeType>> {
    const values: Array<ISelectItem<ReportRangeType>> = [];
    HelperMethods.enumKeys(ReportRangeType).forEach((t: any) => {
      values.push({
        text: this.$t(`reportRangeType.${t}`, { year: this.activeReport?.specificYear }).toString(),
        value: t,
      });
    });
    return values;
  }

  public get updatedUomOptions(): Array<ISelectItem<string>> {
    const uomSystemKeyOptions: Array<ISelectItem<string>> = [];
    this.measurementSystemOptions.forEach((t: IAvailableMeasurementSystemsViewModel) => {
      uomSystemKeyOptions.push({
        text: this.$t('measurementSystemsUnits', { system: t.systemName }).toString(),
        value: t.systemKey
      });
    });
    return uomSystemKeyOptions;
  }

  private get headerText(): string {
    return this.executedReport.name;
  }

  public get currentTimeRange(): string {
    return this.reportTimeSettings.type;
  }

  public get currentMeasurementSystem(): string {
    return reports.currentMeasurementSystem;
  }

  public get rangeStart(): string {
    return this.reportTimeSettings?.range?.fromDate;
  }

  public get rangeEnd(): string {
    return this.reportTimeSettings?.range?.toDate;
  }

  public get selectedTime(): string {
    return reports.selectedTime;
  }

  public get skipSubscriptionTrigger(): boolean {
    return reports.rangeTypeUpdated;
  }

  public set skipSubscriptionTrigger(value: boolean) {
    reports.setRangeTypeUpdated(value);
  }

  public get nodeNames(): string[] {
    const nodeNames: string[] = [];
    const report = this.activeReport;
    this.chartOptions.series.forEach((series) => {
      let node = null;
      report.reportVariableGroups.forEach( (group) => {
        node = group.reportVariables.find((variable)=> variable.key === (series as any).id);
        if (node) {
          nodeNames.push(group.name ?? '');
          return;
        }
      });

      if (node == null) {
        report.reportVariableGroups.forEach( (group) => {
          node = group.reportVariables.find((variable)=> variable.key === `${(series as any).id}-data-node`);
          if (node) {
            nodeNames.push(group.name ?? '');
            return;
          }
        });
      }
    });
    return nodeNames;
  }

  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;

  public setSelectedTime(time: string) {
    reports.setSelectedTime(time);
  }

  public onRangeSkip(direction: SkipDirectionsEnum) {
    this.skipSubscriptionTrigger = true;
    let skipCounter = reports.skipCounter;
    skipCounter += direction === SkipDirectionsEnum.Forward ? 1 : -1;
    reports.setSkipCounter(skipCounter);
    reports.skipDashboardTimeRange(direction);
    reports.setReportRangeType(ReportRangeType.Custom);
    this.$nextTick(() => {
      this.refreshReport();
    });
  }

  public setReportTimeRange(range: IDateRange) {
    const newRange: IDateRange = DateRange.prepareRangeForServer(range);
    reports.updateReportTimeRange(newRange);
    this.refreshReport();
  }

  public toggleSeriesLabel(seriesOption: ReportSeriesOptionsType) {
    const match = this.hiddenSeries.find((s) => s.id === seriesOption.id);
    if (match) {
      this.hiddenSeries = this.hiddenSeries.filter((s) => s.id !== seriesOption.id);
    } else {
      this.hiddenSeries.push(seriesOption);
    }
  }

  public getVariableDisabledClass(seriesOption: ReportSeriesOptionsType) {
    const match = this.hiddenSeries.find((s) => s.id === seriesOption.id);
    if (match) {
      return 'variable__disabled';
    } else {
      return '';
    }
  }

  public getRemarks(variable: any): string {
    return variable.state ? this.$t(`report.variable.${variable.state}`).toString() : '';
  }

  public getMergedRemarks(variable: any): string {
    let remarks = '';

    if (variable.mergedDetails) {
      const pres = (variable.mergedDetails.preDisplayNames.join(', ') as string).replaceLastCommaWith(' and');
      const posts = (variable.mergedDetails.postDisplayNames.join(', ') as string).replaceLastCommaWith(' and');

      switch (variable.mergedDetails.mergedState) {
        case ReportVariableMergedState.Merged:
          remarks = this.$t('report.variable.Merged', { variables: pres, mergedVariable: posts }).toString();
          break;

        case ReportVariableMergedState.Unmerged:
          remarks = this.$t('report.variable.Unmerged', { variable: pres, unmergedVariables: posts }).toString();
          break;
      }
    }

    return remarks;
  }

  // Properties
  public ReportVariableState = ReportVariableState;
  public highchartsCounter = 0;

  // Fields
  // Getters
  // Lifecycle Handlers
  // private beforeCreate(): void {}
  private created(): void {
    this.supportsDrillDown = false;
    this.chartWidth = undefined;
    this.chartHeight = 321;
    // EventBus.$on('NAV_LOCKED', this.onNavLock);
  }

  public mounted(): void {
    eventBus.$on('downloadCSV', this.downloadCSV);
  }

  public beforeDestroy(): void {
    // EventBus.$off('NAV_LOCKED', this.onNavLock);
    eventBus.$off('downloadCSV', this.downloadCSV);
  }

  // Private Methods
  public downloadCSV() {
    const reportChart = this.$refs.reportChart as any;
    reportChart.chart.downloadCSV();
  }
  public async onTimeRangeChange(option: ReportRangeType) {
    reports.setSkipCounter(0);
    await reports.setReportRangeType(option);
    await this.refreshReport();
  }

  public onMeasurementSystemChange(option: string) {
      reports.setCurrentMeasurementSystem(option);
      this.refreshReport(true);
  }

  public toggleGridLines(value: boolean): void {
    reports.setIsGridLines(value);
  }
  public refreshChart() {
    this.highchartsCounter++;
  }

  private onNavLock() {
    this.refreshChart();
  }
  public async refreshReport(measurementSystemChanged: boolean = false) {
    await reports.requestVariablesData({
      key: this.currentMeasurementSystem,
      changed: measurementSystemChanged
    });

    this.refreshChart();
  }
  protected getExecutedReport(): IReportViewModel {
    return this.executedReport;
  }
  // Override
  protected getActiveReport(): IReportViewModel {
    return this.activeReport;
  }
  // Override
  protected getTitleOptions(): TitleOptions {
    return {
      align: 'center',
      style: {
        color: '#FFFFFF',
        bottom: 0,
        position: 'absolute',
      },
      text: this.headerText,
    };
  }
  // Override
  protected getReportData(): Array<IReportDataViewModel> {
    return this.reportData;
  }
  // Override
  protected drillDown(unused: Point): void {
    // Push a snapshot of the current state before time settings change.
    reports.saveSnapshotBeforeDrillDown();
  }
  // Override
  protected tooltipFormatter(): (this: TooltipFormatterContextObject) => string {
    const executedReport = this.getActiveReport;
    const vueInstance = this;
    const highchartFormatHelper = this.highchartFormatHelper;
    return function(this: TooltipFormatterContextObject): string {
      const report: IReportViewModel = executedReport();
      const date: Date = new Date(this.x);
      const dateTimeValue: string = highchartFormatHelper.date(
        date,
        new DateRange({ fromDate: report.fromDate, toDate: report.toDate })
      );
      const dateMarkup: string = `<div title="${dateTimeValue}" style="color: #dadada; font-weight: bold;">${dateTimeValue}</div>`;

      return this.points.reduce((s, point) => {
        const formattedValue = vueInstance.$n(point.y, 'decimal');
        const options = point.series.options as ReportSeriesOptionsType;
        const unitOfMeasurement = options.reportVariable?.unitOfMeasurement ? vueInstance.$t(`global.uom.${options.reportVariable.unitOfMeasurement}`).toString() : '';
        const variableColor = point.color;
        let nodeName = '';
        report.reportVariableGroups.forEach( (group) => {
          const node = group.reportVariables.find((variable) => variable.key === options.id);
          if (node) {
            nodeName = group.name ?? '';
            return;
          }
        });
        const circle = `<div style="height: 100%; display: inline-flex; align-content: center;"><span style="background-color: ${variableColor}; display: inline-block; border-radius: 50%; width: 10px; height: 10px;"></span></div>`;
        return `<span style="color: #dadada;">${s} ${circle} ${point.series.name} (${nodeName}) - ${formattedValue} ${unitOfMeasurement}</span><br>`;
      }, dateMarkup);

    };
  }
  // Watchers
  @Watch('reportData')
  private reportDataChanged(): void {
    // Forces highcharts component to re-render.
    // Otherwise, parts of line charts go missing.
    this.refreshChart();
  }
  // Emitters
  @Emit('refresh')
  public refresh() {}
}
