export enum ReportRangeType {
  Today = 'Today',
  Yesterday = 'Yesterday',
  Last24Hours = 'Last24Hours',
  Last7Days = 'Last7Days',
  Last30Days = 'Last30Days',
  Last90Days = 'Last90Days',
  Last180Days = 'Last180Days',
  MonthToDate = 'MonthToDate',
  YearToDate = 'YearToDate',
  Custom = 'Custom',
  SpecificYear = 'SpecificYear'
}

export enum TotalBy {
  None = 'None',
  Hour = 'Hour',
  Day = 'Day',
  Month = 'Month',
  Quarter = 'Quarter',
  Year = 'Year',
  All = 'All'
}

export enum GraphType {
  Line = 'Line',
  Spline = 'Spline',
  Column = 'Column'
}

export enum SeriesTypes {
  Area = 'Area',
  AreaSpline = 'AreaSpline',
  Line = 'Line',
  Spline = 'Spline',
  Column3d = 'Column3d',
  Column = 'Column',
  Unknown = 'Unknown'
}

export enum ReportAxis {
  Left = 'Left',
  Right = 'Right'
}

export enum SkipDirectionsEnum {
  Backwards = 'Backwards',
  Forward = 'Forward'
}

export enum SortOrderEnum {
  Ascending = 'Asc',
  Descending = 'Desc'
}
