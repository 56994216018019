import { Vue } from 'vue-property-decorator';
import { BehaviorSubject, Subscription } from 'rxjs';
import { first, skipWhile } from 'rxjs/operators';

export const eventBus = new Vue();

export enum Events {
  // TODO: Add local events here
}

export enum ParentEvents {
  RouteChange = 'onpoint::routeChange',
  ActiveCustomerChanged = 'user::ActiveCustomerChanged'
}

export function configureEventBuses() {
  eventBus.$off();
}

export type DataStatusHandler<T> = (status: T) => (void | Promise<void>);

export class DataStatus<TDataStatus extends Record<string, any>> {
  private readonly subject$: BehaviorSubject<TDataStatus>;
  public get status(): TDataStatus {
    return this.subject$.getValue();
  }

  constructor(dataStatus: TDataStatus) {
    this.subject$ = new BehaviorSubject<TDataStatus>(dataStatus);
  }

  public emit(status: Partial<TDataStatus>): void {
    this.subject$.next({ ...this.status, ...status });
  }

  public subscribe(handler: DataStatusHandler<TDataStatus>): Subscription {
    return this.subject$.subscribe(async (status: TDataStatus) => {
      await handler(status);
    });
  }

  public subscribeOnce(handler: DataStatusHandler<TDataStatus>): void {
    this.subject$.pipe(first()).subscribe(async (status: TDataStatus) => {
      await handler(status);
    });
  }

  public subscribeUntil(
    predicate: (status: TDataStatus) => boolean,
    handler: DataStatusHandler<TDataStatus>
  ) {
    this.subject$
      .pipe(
        skipWhile((status: TDataStatus) => !predicate(status)),
        first()
      )
      .subscribe(async (status: TDataStatus) => {
        await handler(status);
      });
  }
}

declare global {
  interface Window {
    eftEventBus: Vue;
  }
}
