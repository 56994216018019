export default class EftTime {
  /*
      This class will probably end up being a collection of methods taking
      reportVariables such as month/year to determine
      how long the month is and such. For now let's not complicate it.
   */
  public static second = 1;
  public static minute = 60;
  public static hour = 60 * 60;
  public static day = 60 * 60 * 24;
  public static week = 60 * 60 * 24 * 7;
  public static month = 60 * 60 * 24 * 30;
  public static quarter = 60 * 60 * 24 * 30 * 3;
  public static year = 60 * 60 * 24 * 365;
}
