import { AxiosInstance } from 'axios';
import { IRecentViewModel, IUserFavoriteViewModel } from '@/view-models/user/user-view-models';
import ControllerBaseService from './common/controller-base-service';
import { userManagementHttp } from './common/http';

export class UserService extends ControllerBaseService {

  constructor(axios: AxiosInstance) {
    super('api/user', axios);
  }

  public static factory(): UserService {
    return new UserService(userManagementHttp.instance);
  }

  public async addRecent(request: IRecentViewModel): Promise<void> {
    await this.httpPost('recents', request);
  }

  public getFavorites(): Promise<IUserFavoriteViewModel[]> {
    return this.httpGet<IUserFavoriteViewModel[]>('favorites/tiles');
  }

  public async addFavorite(request: IUserFavoriteViewModel): Promise<void> {
    await this.httpPost('favorites', request);
  }

  public async removeFavorite(request: IUserFavoriteViewModel): Promise<void> {
    await this.httpDelete(`favorite/${request.key}`);
  }
}
