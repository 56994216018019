

















































































// Libraries
import { Component, Mixins } from 'vue-property-decorator';
import { Nil } from '@/shared/types';
// View Models
import { IReportViewModel } from '@/view-models/reports';
// Components
import ReportVariablesTree from '@/components/reportVariables/ReportVariablesTree.vue';
import SelectedVariables from '@/components/reportVariables/selectedVariables/SelectedVariables.vue';
import ReportTimeFrameSection from '@/components/reportTimeFrame/ReportTimeFrameSection.vue';
import AppReady from '@/components/mixins/app-ready';
import Loading from '@/components/common/Loading.vue';
// Stores
import reports from '@/store/reports';
import reportVariablesTree from '@/store/report-variables-tree';

enum ExpansionsEnum {
  Variables = 'variables',
  TimeFrame = 'time-frame'
}

@Component({
  name: 'settings',
  components: {
    ReportVariablesTree,
    ReportTimeFrameSection,
    SelectedVariables,
    Loading
  },
})
export default class SettingHomePage extends Mixins(AppReady) {
  // VUE.JS Props
  public loadingReport: boolean = true;
  // Refs
  // VUEX
  // Properties
  // Fields
  private expandedRow: Nil<ExpansionsEnum> = ExpansionsEnum.Variables;

  // Getters
  private get activeReport(): IReportViewModel {
    return reports.activeReport;
  }
  public get isDisabled(): boolean {
    return this.isVariableSelectionActive;
  }
  public get isVariableSelectionActive(): boolean {
    return this.activeReport?.reportVariables?.length > 0;
  }
  public get isVariablesExpanded(): boolean {
    return this.expandedRow === ExpansionsEnum.Variables;
  }
  public get isTimeFrameExpanded(): boolean {
    return this.expandedRow === ExpansionsEnum.TimeFrame;
  }
  public get currentMeasurementSystem(): string {
    return reports.currentMeasurementSystem;
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  public async beforeMount(): Promise<void> {
    this.loadingReport = true;
    await reportVariablesTree.fetchAssignmentTree();
    const noReportData = this.activeReport.key === '';
    const reportKey = this.$route.params.key;
    if (reportKey && noReportData) {
      if (reportKey && reports.activeReport.key !== reportKey) {
        await reports.loadReportById(reportKey);
      }
      await reports.requestVariablesData({ key: this.currentMeasurementSystem, changed: false });

    }
    this.loadingReport = false;
  }
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  private expand(name: ExpansionsEnum): void {
    this.expandedRow = this.expandedRow === name ? null : name;
  }

  // Event Methods
  public expandVariables(): void {
    this.expand(ExpansionsEnum.Variables);
  }
  public expandTimeFrame(): void {
    this.expand(ExpansionsEnum.TimeFrame);
  }
  public runReport() {
    this.$router.push(`/view/${this.activeReport.key}`);
  }

  // Watchers
  // Emitters
}
