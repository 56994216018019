export enum AccessSettingsEnum {
  Internal = 'Internal',
  Reports = 'Reports',
  Dashboards = 'Dashboards',
  All = 'All'
}

export enum MeasurementTypesEnum {
  Unknown = 'Unknown',
  Boolean = 'Boolean',
  Numeric = 'Numeric',
  Concentration = 'Concentration',
  Distance = 'Distance',
  Energy = 'Energy',
  FlowRate = 'FlowRate',
  O2MoleFraction = 'O2MoleFraction',
  Pressure = 'Pressure',
  Temperature = 'Temperature',
  Text = 'Text',
  FuelPropertyEnergy = 'FuelPropertyEnergy',
  Area = 'Area',
  FuelPressure = 'FuelPressure'
}
