import ControllerBaseService from '@/services/common/controller-base-service';
import { AxiosInstance } from 'axios';
import { IReport } from '@/view-models/reports';
import { http } from './common/http';

export class ReportsService extends ControllerBaseService {

  constructor(axios: AxiosInstance) {
    super('reports', axios);
  }

  public static factory(): ReportsService {
    return new ReportsService(http.instance);
  }

  public getAll(customerKey: string): Promise<IReport[]> {
    return this.httpGet<IReport[]>('', { customerKey });
  }

  public get(key: string, customerKey: string): Promise<IReport> {
    return this.httpGet<IReport>(key, { customerKey });
  }

  public async existsByName(name: string, customerKey: string): Promise<IReport> {
    const encoded = encodeURIComponent(encodeURIComponent(name));
    return this.httpGet<IReport>(`exists/${encoded}`, { customerKey });
  }

  public save(report: IReport, customerKey: string): Promise<IReport> {
    return this.httpPut<IReport>('', report, null, { customerKey });
  }

  public modify(report: IReport, customerKey: string): Promise<IReport> {
    return this.httpPut<IReport>('', report, null, { customerKey });
  }

  public remove(key: string, customerKey: string): Promise<void> {
    return this.httpDelete<void>(key, { customerKey });
  }
}
