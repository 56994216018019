import Vue from 'vue';
import Vuex from 'vuex';
import { IUserModule } from './user';
import { IErrorModule } from './error';
import { IAppModule } from './app';
import { IReportsModule } from './types/reports';

Vue.use(Vuex);

interface IState {
  app: IAppModule;
  error: IErrorModule;
  user: IUserModule;
  reports: IReportsModule;
}

export default new Vuex.Store<IState>({});
