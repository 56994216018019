import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Vue from 'vue';
import { ReportRangeType, SkipDirectionsEnum } from '@/enums/report-enums';
import { ReportsService } from '@/services/reports-service';
import { DataStatus } from '@/shared/event-bus';
import { dateInBetween, startOfDay } from '@/shared/date-time-utils';
import { TimerLock } from '@/shared/schedulers';
import { isMethod } from '@/shared/types';
import moment from 'moment';
import { DateRange, IDateRange, IReportTimeRangeSetting } from '@/view-models/report-time-model';
import {
  IGroupedReportVariables,
  IReport,
  IReportDataViewModel,
  IReportSnapshot, IReportVariableUomModel,
  IReportVariableViewModel,
  IReportViewModel,
  ReportSnapshot,
  ReportVariableViewModel,
  ReportViewModel
} from '@/view-models/reports';
import store from '.';
import { IReportClonePayload, IReportDependenciesStatus, IReportsModule } from './types/reports';
import { skipDateRange } from '@/shared/time-helper-methods';
import { globalErrorHandler } from '@/shared/error-parser';
import { UserService } from '@/services/user-service';
import { RecentType } from '@/enums/recents';
import { appRouteBase } from '@/shared/from-parent';
import { IUserFavoriteViewModel } from '@/view-models/user/user-view-models';
import { EntityType } from '@/enums/entities';
import { isStringEmpty } from '@/shared/string-utils';
import { UnitOfMeasurementEnum } from '@/enums/unit-of-measurement';
import ReportHelper, { ReportField } from '@/shared/report-helper';
import VariableDataService from '@/services/variable-data-service';
import {
  IVariableDataOptions,
  IVariableDataRequest,
  IVariableDataResponse,
  IVariableRequest
} from '@/view-models/variable-data-view-models';
import { ReportVariableState } from '@/enums/report-variable-state';
import user from '@/store/user';

@Module({ dynamic: true, store, name: 'reports2' })
export class ReportsModule extends VuexModule implements IReportsModule {
  // State
  public reportsList: IReport[] = [];
  public loadingReports: boolean = false;
  public activeReport: IReportViewModel = new ReportViewModel(); // using
  public selectedVariableCount: number = 0;
  public fetchingReportData: boolean = false;
  public executedReport: IReportViewModel = new ReportViewModel();
  public priors: Array<IReportSnapshot> = [];
  public reportData: Array<IReportDataViewModel> = [];
  public variablesSearchResults: string[] = null;
  public rangeTypeUpdated: boolean = false;
  public selectedTime: string = null;
  public latestToDate: Date = new Date(0);
  public pendingSnapshot?: IReportSnapshot;
  public reportTimeSettings: IReportTimeRangeSetting = {
    range: new DateRange({ fromDate: startOfDay().toISOString(), toDate: new Date().toISOString() }),
    type: ReportRangeType.Today,
  };
  public skipCounter: number = 0;
  public currentMeasurementSystem: string = '';

  // Getters
  public get isDirty(): boolean {
    return isMethod(this.activeReport, 'isDirty') ? this.activeReport.isDirty() : false;
  }

  public get isFetchingReportData(): boolean {
    return this.fetchingReportData;
  }

  public get getReportVariableCopy() {
    return (variable: IReportVariableViewModel): IReportVariableViewModel => {
      const reportVariableOriginal = this.activeReport.reportVariables.find((ri) => ri.key === variable.key);
      return new ReportVariableViewModel(reportVariableOriginal);
    };
  }

  // Mutations
  @Mutation
  public resetStoreState(): void {
    // this.availableVariables = []; // using
    this.reportsList = [];
    this.activeReport = new ReportViewModel(); // using
    this.fetchingReportData = false;
    this.executedReport = new ReportViewModel();
    this.priors = [];
    this.reportData = [];
    this.variablesSearchResults = null;
    this.rangeTypeUpdated = false;
    this.skipCounter = 0;
    this.selectedVariableCount = 0;
  }

  @Mutation
  public setReportFave(payload: { key: string; isFave: boolean }): void {
    const foundIndex = this.reportsList.findIndex((report) => report.key === payload.key);
    if (foundIndex > -1) {
      const report = new ReportViewModel(
        Object.assign({}, this.reportsList[foundIndex], { isFavorite: payload.isFave })
      );
      this.reportsList.splice(foundIndex, 1, report);
    }
    if (this.activeReport.key === payload.key) {
      this.activeReport = new ReportViewModel(Object.assign({}, this.activeReport, { isFavorite: payload.isFave }));
    }
    if (this.executedReport.key === payload.key) {
      this.executedReport = new ReportViewModel(Object.assign({}, this.executedReport, { isFavorite: payload.isFave }));
    }
  }

  @Mutation
  public setLoadingReports(flag: boolean): void {
    this.loadingReports = flag;
  }

  @Mutation
  public setCurrentMeasurementSystem(option: string): void {
    this.currentMeasurementSystem = option;
  }

  @Mutation
  public resetReport(): void {
    this.activeReport = new ReportViewModel();
    this.activeReport.fromDate = startOfDay().toString();
    this.activeReport.toDate = new Date().toString();
    this.selectedVariableCount = 0;
  }

  @Mutation
  public setReportsList(reports: IReport[]): void {
    this.reportsList = reports;
  }

  @Mutation
  public addReportToList(report: IReportViewModel): void {
    const match = this.reportsList.find((r) => r.key === report.key);
    if (!match) {
      const list = this.reportsList;
      list.push(report);
      this.reportsList = list;
    }
  }

  @Mutation
  public setReportName(newName: string): void {
    this.activeReport.name = newName;
  }

  @Mutation
  public setReportTimeSettings(data: { range: IDateRange; type: ReportRangeType }) {
    this.reportTimeSettings = Object.assign({}, data);
  }

  @Mutation
  public setReportDateRange(range?: IDateRange): void {
    if (range != null) {
      this.reportTimeSettings = Object.assign(this.reportTimeSettings, { range });
    } else {
      this.reportTimeSettings = Object.assign(this.reportTimeSettings, {
        range: new DateRange({ fromDate: this.activeReport.fromDate, toDate: this.activeReport.toDate }),
        type: ReportRangeType.Custom,
      });
    }
  }

  @Mutation
  public skipDashboardTimeRange(direction: SkipDirectionsEnum): void {
    const newRange = skipDateRange(this.reportTimeSettings.range, direction, this.reportTimeSettings.type);

    if (this.reportTimeSettings.type === ReportRangeType.SpecificYear) {
      this.activeReport.specificYear = parseInt(newRange.fromDate, 10); // get year of fromDate
    }

    setTimeRange(this, this.reportTimeSettings.type, newRange);
  }

  @Mutation
  public setSelectedTime(time?: string): void {
    this.selectedTime =
      time == null ||
      this.activeReport.fromDate == null ||
      (this.activeReport.fromDate <= time && this.activeReport.toDate >= time)
        ? time
        : null;
  }

  @Mutation
  public removeReportByKey(key: string): void {
    this.reportsList = this.reportsList.filter((r) => r.key !== key);
  }

  @Mutation
  public setReport(value: IReportViewModel): void {
    this.activeReport = value;
  }

  @Mutation
  public updateSelectedVariableCount(props: { value: number, remove?: boolean }): void {
    const removeVariable = props.remove || false;
    removeVariable ? this.selectedVariableCount -= props.value : this.selectedVariableCount += props.value;
  }
  @Mutation
  public setSelectedVariableCount(value: number): void {
    this.selectedVariableCount = value;
  }
  @Mutation
  public setFetchingReportData(value: boolean): void {
    this.fetchingReportData = value;
    if (value) {
      this.reportData = [];
    }
  }

  @Mutation
  public setExecutedDateRange(range: IDateRange): void {
    if (this.executedReport !== null) {
      this.executedReport.fromDate = range.fromDate;
      this.executedReport.toDate = range.toDate;
    }
  }

  @Mutation
  public setReportRangeTypeUpdate(type: ReportRangeType): void {
    this.reportTimeSettings = Object.assign(this.reportTimeSettings, { type });
    this.activeReport.rangeType = type;
  }

  @Mutation
  public updateActiveReportVariable(data: IReportVariableViewModel): void {
    const index: number = this.activeReport.reportVariables.findIndex(
      (t: IReportVariableViewModel) => t.key === data.key
    );
    Vue.set(this.activeReport.reportVariables, index, data);
  }

  @Mutation
  public updateRangeFromReportRangeType(): void {
    const existingRange = new DateRange({ fromDate: this.activeReport.fromDate, toDate: this.activeReport.toDate });
    this.activeReport.determineRange(existingRange);
    this.reportTimeSettings = Object.assign(this.reportTimeSettings, {
      range: new DateRange({ fromDate: this.activeReport.fromDate, toDate: this.activeReport.toDate }),
      type: this.activeReport.rangeType,
    });
  }
  @Mutation
  public updateReportTimeRange(range: IDateRange): void {
    const updatedRange = new DateRange(range);
    const newDObj = { fromDate: updatedRange.fromDate, toDate: updatedRange.toDate };
    setTimeRange(this, ReportRangeType.Custom, newDObj);
  }

  @Mutation
  public addVariableChartData(variableChartData: IReportDataViewModel): void {
    this.reportData.push(variableChartData);
  }

  @Mutation
  public addReportData(data: Array<IReportDataViewModel>): void {
    this.reportData.concat(data);
  }

  @Mutation
  public toggleReportVariableGroup(key: string): void {
    const match = this.activeReport.reportVariableGroups.find((group) => group.key === key);
    if (match) {
      match.isOpen = !match.isOpen;
    }
  }

  @Mutation
  public addReportVariable(data: IReportVariableViewModel): void {
    this.activeReport.reportVariables.push(data);

    const match = this.activeReport.reportVariableGroups.find(
      (group: IGroupedReportVariables) => group.key === data.nodeKey
    );

    if (match) {
      match.reportVariables.push(data);
    } else {
      const groupName = data.topDownHierarchyPathName;
      const newReportVariableGroup: IGroupedReportVariables = {
        key: data.nodeKey,
        name: groupName,
        reportVariables: [data],
        isOpen: true,
      };

      this.activeReport.reportVariableGroups.push(newReportVariableGroup);
    }
  }

  @Mutation
  public addReportDataSeries(data: IReportVariableViewModel): void {
    this.activeReport.reportVariables.push(data);
  }

  @Mutation
  public removeVariableChartData(variableChartData: IReportDataViewModel): void {
    this.reportData = this.reportData.filter((i: IReportDataViewModel) => i.key !== variableChartData.key);
  }

  @Mutation
  public removeAllReportVariables() {
    this.activeReport.reportVariables = [];
    this.activeReport.reportVariableGroups = [];
  }

  @Mutation
  public removeReportVariableByKey(reportVariableKey: string) {
    this.activeReport.reportVariables = this.activeReport.reportVariables.filter((reportVariable) => {
      return reportVariable.key !== reportVariableKey;
    });

    const matchedReportVariableGroup = this.activeReport.reportVariableGroups.find(
      (variableGroup) => variableGroup.reportVariables.find((variable) => variable.key === reportVariableKey) != null
    );
    if (matchedReportVariableGroup) {
      matchedReportVariableGroup.reportVariables = matchedReportVariableGroup.reportVariables.filter(
        (reportVariable) => reportVariable.key !== reportVariableKey
      );
    }

    if (matchedReportVariableGroup.reportVariables.length === 0) {
      this.activeReport.reportVariableGroups = this.activeReport.reportVariableGroups.filter(
        (variableGroup) => variableGroup.key !== matchedReportVariableGroup.key
      );
    }
  }

  @Mutation
  public removeReportVariable(data: { reportVariableGroupKey: string; reportVariableKey: string }) {
    this.activeReport.reportVariables = this.activeReport.reportVariables.filter((reportVariable) => {
      return reportVariable.key !== data.reportVariableKey;
    });

    const matchedReportVariableGroup = this.activeReport.reportVariableGroups.find(
      (variableGroup) => variableGroup.key === data.reportVariableGroupKey
    );
    if (matchedReportVariableGroup) {
      matchedReportVariableGroup.reportVariables = matchedReportVariableGroup.reportVariables.filter(
        (reportVariable) => reportVariable.key !== data.reportVariableKey
      );
    }

    if (matchedReportVariableGroup.reportVariables.length === 0) {
      this.activeReport.reportVariableGroups = this.activeReport.reportVariableGroups.filter(
        (variableGroup) => variableGroup.key !== matchedReportVariableGroup.key
      );
    }
  }

  @Mutation
  public reorderReportVariables(): void {
    let sortOrder = 1;
    this.activeReport.reportVariables.forEach((i: IReportVariableViewModel) => {
      i.sortOrder = sortOrder;
      sortOrder++;
    });

    this.activeReport.reportVariableGroups.forEach((variableGroup) => {
      sortOrder = 0;
      variableGroup.reportVariables.forEach((variable) => {
        variable.sortOrder = ++sortOrder;
      });
    });
  }

  @Mutation
  public setVariableChartData(variableChartData: Array<IReportDataViewModel>): void {
    this.reportData = variableChartData;
  }

  @Mutation
  public setIsGridLines(value: boolean): void {
    this.executedReport.showGridLines = value;
  }

  @Mutation
  public saveSnapshotBeforeDrillDown(): void {
    this.pendingSnapshot = new ReportSnapshot({
      report: this.activeReport,
      executedReport: this.executedReport,
      reportData: this.reportData,
    });
  }

  @Mutation
  public setReportData(data: Array<IReportDataViewModel>): void {
    this.executedReport = new ReportViewModel(this.activeReport);
    this.activeReport = new ReportViewModel(this.activeReport);
    this.reportData = data;

    // set variable statuses on report variables based on data
    // and merged details on data based on report variables
    this.reportData?.forEach((datum) => {
      const match = this.activeReport.reportVariables.find((vm) => vm.key === datum.key);
      if (match != null) {
        match.state = datum.state;
        datum.mergedDetails = match.mergedDetails;
      }
    });
  }

  @Mutation
  public updateVariableData(data: IReportDataViewModel) {
    const index = this.reportData.findIndex((d) => d.key === data.key);
    if (index >= 0) {
      Vue.set(this.reportData, index, data);
    }
  }

  @Mutation
  public updateVariableUoms(data: Array<IVariableDataResponse>): void {
    this.activeReport.reportVariables.forEach((variable) => {
      const match = data.find((response) =>
          response.variableKey === ReportHelper.getVariableKey(variable));
      if (match) {
        variable.unitOfMeasurement = match.unitOfMeasurement;
      }
    });
  }

  @Mutation
  public setVariablesSearchResults(results: string[]): void {
    this.variablesSearchResults = results;
  }

  @Mutation
  public setSkipCounter(value: number): void {
    this.skipCounter = value;
  }

  @Mutation
  public setRangeTypeUpdated(value: boolean): void {
    this.rangeTypeUpdated = value;
  }

  // Actions
  @Action({ rawError: true })
  public async fetchReportsList() {
    this.setLoadingReports(true);
    const faves = await UserService.factory().getFavorites();
    const customerKey: string = user.currentUser?.activeCustomerKey;
    const reports = (await ReportsService.factory().getAll( customerKey )).map((report) => {
      const found = faves.find(
        (fave) => fave.key === report.key && favoriteIsReportEntity(fave)
      );
      report.isFavorite = found != null;
      return report;
    });

    this.setReportsList(reports);
    this.setLoadingReports(false);
  }

  @Action({ rawError: true })
  public async removeReport(key: string) {
    const customerKey: string = user.currentUser.activeCustomerKey;
    await ReportsService.factory().remove( key, customerKey );
    this.removeReportByKey(key);
  }

  @Action({ rawError: true })
  public async overwriteExistingReport(id: string): Promise<IReportViewModel> {
    const customerKey: string = user.currentUser.activeCustomerKey;
    const targetReport: IReport = await ReportsService.factory().get(id, customerKey);
    const report: IReportViewModel = new ReportViewModel(this.activeReport);
    const toReportIndex = this.reportsList.findIndex((r) => r.key === report.key);
    report.key = id;
    const priorName = report.name;
    if (targetReport != null) {
      report.name = targetReport.name;
    }
    const savedReport: IReport = await ReportsService.factory().modify(report, customerKey);
    // Don't change name of current report.
    const newCurrentReport = new ReportViewModel(savedReport);
    newCurrentReport.name = priorName;
    this.setReport(newCurrentReport);
    this.executedReport.name = newCurrentReport.name;
    this.reportsList[toReportIndex] = newCurrentReport;
    this.setReportsList(this.reportsList);
    return newCurrentReport;
  }

  @Action({ rawError: true })
  public async loadReportById(id: string): Promise<boolean> {
    const customerKey: string = user.currentUser.activeCustomerKey;
    const response: IReport = await ReportsService.factory().get(id, customerKey);

    const report: IReportViewModel = new ReportViewModel(response);
    report.isFavorite = (await UserService.factory().getFavorites()).some(
      (fave) => fave.key === id && favoriteIsReportEntity(fave)
    );

    this.setReportTimeSettings({
      range: new DateRange({ fromDate: report.fromDate, toDate: report.toDate }),
      type: report.rangeType,
    });

    report.resetChecksum();

    this.setReport(report);
    UserService.factory().addRecent({
      name: report.name,
      recentType: RecentType.Report,
      route: `${appRouteBase()}view/${report.key}`,
      timestampUtc: new Date(),
    });

    return true;
  }

  @Action({ rawError: true })
  public async saveNewReport(reportName: string): Promise<IReportViewModel> {
    const customerKey: string = user.currentUser.activeCustomerKey;
    const report: IReportViewModel = new ReportViewModel(this.activeReport);
    report.prepareForNewSave(reportName);
    const savedReport = new ReportViewModel(await ReportsService.factory().save(report, customerKey));
    this.setReport(savedReport);
    this.executedReport.name = savedReport.name;
    return savedReport;
  }

  @Action({ rawError: true })
  public async getReportByName(name: string): Promise<IReportViewModel> {
    const customerKey: string = user.currentUser.activeCustomerKey;
    const response = await ReportsService.factory().existsByName(name, customerKey);
    const report = new ReportViewModel(response);
    return isStringEmpty(report?.key) ? null : report;
  }

  @Action({ rawError: true })
  public async updateReport(): Promise<void> {
    const customerKey: string = user.currentUser.activeCustomerKey;
    let report: IReportViewModel = new ReportViewModel(this.activeReport);
    const response = await ReportsService.factory().save(report, customerKey);
    report = new ReportViewModel(response);
    this.setReport(report);
  }

  @Action({ rawError: true })
  public async cloneReport(payload: IReportClonePayload): Promise<void> {
    let found = this.reportsList.find((r) => r.key === payload.reportKey);
    const customerKey: string = user.currentUser.activeCustomerKey;
    found = found ?? (await ReportsService.factory().get(payload.reportKey, customerKey));

    if (found != null) {
      const cloned = Object.assign({}, found, { name: payload.newName, key: undefined });
      const saved = await ReportsService.factory().save(cloned, customerKey);
      this.addReportToList(new ReportViewModel(saved));
    }
  }

  @Action({ rawError: true })
  public async copyReportIntoAnotherOne(payload: { fromKey: string; toKey: string }): Promise<IReport> {
    const fromReport = this.reportsList.find((r) => r.key === payload.fromKey);
    const toReportIndex = this.reportsList.findIndex((r) => r.key === payload.toKey);
    const customerKey: string = user.currentUser.activeCustomerKey;
    let toReport = this.reportsList[toReportIndex];
    toReport = Object.assign({}, fromReport, { name: toReport.name });
    toReport = await ReportsService.factory().modify(toReport, customerKey);
    this.reportsList[toReportIndex] = toReport;
    this.setReportsList(this.reportsList);
    return toReport;
  }

  @Action({ rawError: true })
  public setReportRangeType(rangeType: ReportRangeType): Promise<void> {
    this.setRangeTypeUpdated(true);
    this.setReportRangeTypeUpdate(rangeType);
    this.updateRangeFromReportRangeType();
    return Promise.resolve();
  }

  @Action({ rawError: true })
  public async requestVariableData(params: { uomModel: IReportVariableUomModel, systemKey: string }): Promise<void> {
    const variable = params.uomModel.reportVariable;
    const variableRequest: IVariableRequest = {
      key: ReportHelper.getVariableKey(variable),
      unitOfMeasurement: params.uomModel.toUoM
    };

    const options: IVariableDataOptions = {
      sampleSize: ReportField.DefaultSampleSize,
      sortAscending: true
    };

    const request: IVariableDataRequest = {
      dateRange: this.activeReport.dateRange,
      variables: [variableRequest],
      // This request is only made when changing an individual variable's uom, so measurement system does not matter.
      measurementSystemKey: null,
      options
    };

    try {
      const response: IVariableDataResponse[] = await VariableDataService.factory().getVariablesData(request);

      if (response.length) {
        const reportData: IReportDataViewModel = {
          key: variable.key,
          data: response[0].data.map((point) => {
            return { timestamp: point[0], data: point[1] };
          }),
          state: response[0].data.length === 0
              ? ReportVariableState.NoData
              : response[0].data.some((point) => point[1] == null)
                  ? ReportVariableState.NonNumericData
                  : ReportVariableState.Ok,
          displayValue: null,
          name: null
        };
        this.updateVariableData(reportData);
        this.updateVariableUoms(response);
      }
    } catch (e) {
      globalErrorHandler(e, true, false);
    } finally {
      this.setFetchingReportData(false);
    }
  }


  @Action( { rawError: true })
  public async requestVariablesData(measurementSystemOptions: { key: string; changed: boolean } ): Promise<void> {
    if (this.skipCounter === 0) {
      this.updateRangeFromReportRangeType();
    }
    const report: IReportViewModel = new ReportViewModel(this.activeReport);
    await this.setReportRangeType(report.rangeType);
    this.setExecutedDateRange(report.dateRange);
    this.setFetchingReportData(true);
    report.checksum = null;

    report.sampleSize = report.reportVariables.length < 6 ? ReportField.DefaultSampleSize :
        getSampleSize(report.dateRange);

    const variables = report.reportVariables.map((v) => {
      const model: IVariableRequest = {
        key: ReportHelper.getVariableKey(v),
        unitOfMeasurement: measurementSystemOptions.changed ? UnitOfMeasurementEnum.Default : v.unitOfMeasurement
      };
      return model;
    });

    const options: IVariableDataOptions = {
      sampleSize: report.sampleSize,
      sortAscending: true // TODO: Remove after we start to enforce always sorting ascending
    };

    const request: IVariableDataRequest = {
      dateRange: report.dateRange,
      variables,
      measurementSystemKey: measurementSystemOptions.key,
      options
    };

    try {
      const data: IVariableDataResponse[] = await VariableDataService.factory().getVariablesData(request);

      const reportDataArray: IReportDataViewModel[] = data.map((dataResponse) => {
        const variable = report.reportVariables.find((variableVm) =>
            ReportHelper.getVariableKey(variableVm) === dataResponse.variableKey);
        const reportData: IReportDataViewModel = {
          key: variable.key,
          data: dataResponse.data.map((point) => {
            return { timestamp: point[0], data: point[1] };
          }),
          state: dataResponse.data.length === 0
              ? ReportVariableState.NoData
              : dataResponse.data.some((point) => point[1] == null)
                  ? ReportVariableState.NonNumericData
                  : ReportVariableState.Ok,
          displayValue: null,
          name: null,
        };
        return reportData;
      });
      this.setReportData(reportDataArray);
      this.updateVariableUoms(data);
    } catch (e) {
      globalErrorHandler(e, true, false);
    } finally {
      this.setFetchingReportData(false);
    }

  }

  @Action({ rawError: true })
  public async faveReport(reportKey: string): Promise<void> {
    const request: IUserFavoriteViewModel = {
      favoritedEntityKey: reportKey,
      favoritedEntityType: EntityType.AssetReport,
    };
    const service = UserService.factory();
    await service.addFavorite(request);
    this.setReportFave({ key: reportKey, isFave: true });
  }

  @Action({ rawError: true })
  public async unfaveReport(reportKey: string): Promise<void> {
    const request: IUserFavoriteViewModel = {
      key: reportKey
    };
    const service = UserService.factory();
    await service.removeFavorite(request);
    this.setReportFave({ key: reportKey, isFave: false });
  }
}

export default getModule(ReportsModule, store);
export const heaterSchemaStatus = new DataStatus<{ ready: boolean }>({ ready: false });
export const reportRunDependenciesStatus = new DataStatus<IReportDependenciesStatus>({
  variables: false,
  schemas: false,
});

const fetchInterval = 1000 * 60 * 10;
const reportsVariablesFetchLock = new TimerLock(fetchInterval);
export { reportsVariablesFetchLock };

function setTimeRange(state: IReportsModule, type: ReportRangeType, range: IDateRange): void {
  const originalEnd = state.reportTimeSettings.range.toDate;
  state.reportTimeSettings = { type, range };
  state.activeReport.dateRange = range;
  state.activeReport.rangeType = type;
  if (originalEnd === state.selectedTime || dateInBetween(range.fromDate, state.selectedTime, range.toDate)) {
    state.selectedTime = null;
  }
  if (range.toDate > state.reportTimeSettings.range.toDate) {
    state.latestToDate = new Date(range.toDate);
  }
}

function favoriteIsReportEntity(favorite: IUserFavoriteViewModel): boolean {
  return favorite.entityType === EntityType.Report || favorite.entityType === EntityType.AssetReport;
}

export function getSampleSize(dateRange: IDateRange): number {
  const fromDate: moment.MomentInput = moment(dateRange.fromDate, 'YYYY-MM-DD');
  const toDate: moment.MomentInput = moment(dateRange.toDate, 'YYYY-MM-DD');
  const days: number = toDate.diff(fromDate, 'days');
  let sampleSize;
  if ( days > 90 ) {
    sampleSize = ReportField.MinimumSampleSize;
  } else if ( days >= 30 ) {
    sampleSize = ReportField.ReducedSampleSize;
  } else {
    sampleSize = ReportField.DefaultSampleSize;
  }
  return sampleSize;
}
