import ControllerBaseService from '@/services/common/controller-base-service';
import { IAssignmentTreeNodeViewModel } from '@/view-models/report-variables-tree/report-variable-node';
import { AxiosInstance } from 'axios';
import { userManagementHttp } from './common/http';

export class AssignmentTreeService extends ControllerBaseService {

  constructor(axios: AxiosInstance) {
    super('users/current', axios);
  }

  public static factory(): AssignmentTreeService {
    return new AssignmentTreeService(userManagementHttp.instance);
  }

  public async getAssignmentTree(): Promise<IAssignmentTreeNodeViewModel[]> {
    return this.httpGet<IAssignmentTreeNodeViewModel[]>('assignmentTree');
  }
}
