





// Libraries
import { Vue, Component } from 'vue-property-decorator';
// View Models
import { BreadCrumbItem } from '@/view-models/breadcrumb';
import { PagesEnum } from '@/router';
// Components
import Breadcrumb from './common/Breadcrumb.vue';

@Component({
  name: 'header-component',
  components:{
    Breadcrumb
  }
})
export default class HeaderComponent extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX
  // Properties
  // Fields
  // Getters
  private get breadcrumbItems(): BreadCrumbItem[] {
    const items: BreadCrumbItem[] = [
      {
        text: this.$t('header.home').toString()
      }
    ];

    switch (this.$route.name) {
      case PagesEnum.viewReport:
        items[0].text = this.$t('generic.reports').toString();
        items[0].to = { name: PagesEnum.home };
        items.push({
          text: this.$t('header.viewReport').toString()
        });
        break;
      case PagesEnum.addReport:
        items[0].text = this.$t('generic.reports').toString();
        items[0].to = { name: PagesEnum.home };
        items.push({
          text: this.$t('header.addReport').toString()
        });
        break;
      case PagesEnum.editReport:
        items[0].text = this.$t('generic.reports').toString();
        items[0].to = { name: PagesEnum.home };
        items.push({
          text: this.$t('header.editReport').toString()
        });
        break;
    }

    return items;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
