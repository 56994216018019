import * as stringUtils from './string-utils';
import * as dateUtils from './date-time-utils';
import moment from 'moment';
import { DateTimeInput } from '@/view-models/report-time-model';
export default class HelperMethods {
  public static asDate(date: any, asIs: boolean = false): Date {
    return dateUtils.asDate(date, asIs);
  }
  public static newGuid(): string {
    return stringUtils.newGuid();
  }
  public static asMoment(date: DateTimeInput) {
    return date == null || date === '' ? null : moment(date);
  }
  public static enumKeys(enumType: any): string[] {
    return Object.keys(enumType)
      .map((k) => enumType[k])
      .filter((v) => typeof v === 'string');
  }
  /**
   * Get the number of days in a particular month.
   * @param date: Date
   * @returns numberOfDays: number
   */
}
