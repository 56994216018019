import { AccessSettingsEnum } from '@/enums/variables';
import ControllerBaseService from '@/services/common/controller-base-service';
import {
    IReportAssetVariableViewModel,
    IReportVariablesViewModel
} from '@/view-models/report-variables-tree/report-variable-node';
import { AxiosInstance } from 'axios';
import { http } from './common/http';

export class ReportVariableService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('assets', axios);
    }

    public static factory(): ReportVariableService {
        return new ReportVariableService(http.instance);
    }

    public async getAssetVariables(assetKey: string): Promise<IReportAssetVariableViewModel[]> {
        const response = await this.httpGet<IReportVariablesViewModel>(
            `${assetKey}/variables`,
            { accessSetting: AccessSettingsEnum.Reports }
        );
        return response?.variables ?? [];
    }
}
