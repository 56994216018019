








































// Libraries
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import moment from 'moment';
import { debounce } from 'lodash';
import HelperMethods from '@/shared/helper-methods';
// View Models
import { IDateRangeObjects } from '@/view-models/report-time-model';
import { SkipDirectionsEnum } from '@/enums/report-enums';
// Components

import DateTimePicker from '@/components/shared/DateTimePicker.vue';
// Services
// Stores

@Component({
  name: 'time-range-skipper',
  components: {
    DateTimePicker,
  }
})
export default class TimeRangeSkipper extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public start: Date;
  @Prop({ default: () => new Date() })
  public end: Date;
  @Prop({ default: true })
  public intoTheFuture: boolean;
  @Prop({ required: true })
  public id: string;
  // VUEX
  // Properties
  public debouncedTriggerSkip = debounce(this.triggerSkip.bind(this), 800);
  // Fields
  // Getters
  public get showForwardButton(): boolean {
    const isAfterNow = moment(this.end).isAfter(moment().seconds(0).milliseconds(0));
    return this.intoTheFuture || !isAfterNow;
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public skipForward() {
    this.debouncedTriggerSkip(SkipDirectionsEnum.Forward);
  }
  public skipBackwards() {
    this.debouncedTriggerSkip(SkipDirectionsEnum.Backwards);
  }
  public onStartRangeChange(date: Date | moment.Moment) {
    const localDate: Date = date != null ? HelperMethods.asDate(date) : null;
    const localStartDate: Date = this.start != null ? HelperMethods.asDate(this.start) : null;

    if (localDate != null && localStartDate != null && localDate.getTime() !== localStartDate.getTime()) {
      this.triggerRangeChange({
        fromDate: HelperMethods.asDate(date),
        toDate: this.end
      });
    }
  }
  public onEndRangeChange(date: Date | moment.Moment) {
    const localDate: Date = date != null ? HelperMethods.asDate(date) : null;
    const localEndDate: Date = this.end != null ? HelperMethods.asDate(this.end) : null;
    if (localDate != null && localEndDate != null
        && Math.trunc(localDate.getTime()/1000) !== Math.trunc(localEndDate.getTime()/1000)) {
      this.triggerRangeChange({
        fromDate: this.start,
        toDate: HelperMethods.asDate(date)
      });
    }
  }
  // Watchers
  // Emitters
  @Emit('skip')
  public triggerSkip(dirEmit: SkipDirectionsEnum) {}
  @Emit('change')
  public triggerRangeChange(rangeEmit: IDateRangeObjects) {}
}
