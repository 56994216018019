import { AxiosInstance } from 'axios';
import ControllerBaseService from './common/controller-base-service';
import { userManagementHttp } from './common/http';
import { IUserViewModel } from '@/view-models/user/user-view-models';

export class UserManagementService extends ControllerBaseService {

  constructor(axios: AxiosInstance) {
    super('users', axios);
  }

  public static factory(): UserManagementService {
    return new UserManagementService(userManagementHttp.instance);
  }

  public async getCurrentUser(): Promise<IUserViewModel> {
    return this.httpGet<IUserViewModel>('current');
  }
}
