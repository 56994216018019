






import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

/**
 * THIS IS A COPY FROM COMMONLIBRARY
 * if you make changes here, make sure they are also done in the COMMONLIBRARY
 *
 * @TODO Not depend on copy pasting dsm components, but just reference them.
 */

@Component({
  name: 'label-button',
})
export default class LabelButton extends Vue {
  @Prop({ required: true })
  public readonly variant!: string;
  @Prop({ required: true })
  public readonly text!: string;
  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('click')
  public onClick(eventEmit: MouseEvent) {}
}
