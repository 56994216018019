






























// Libraries
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { Nil } from '@/shared/types';
import { isStringEmpty } from '@/shared/string-utils';
// View Models
import { IReport } from '@/view-models/reports';
// Components
// Stores
import reports from '@/store/reports';

@Component({
  name: 'report-clone-modal'
})
export default class ReportCloneModal extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public readonly originReport: Nil<IReport>;
  // Refs
  // VUEX
  // Properties
  public processing: boolean = false;
  public newName: string = '';
  // Fields
  private nameExistsKey: Nil<string> = null;
  // Getters
  public get show(): boolean {
    return this.originReport != null;
  }
  public set show(flag: boolean) {
    this.emitClose(flag);
  }
  public get validationMessage(): Nil<string> {
    if (isStringEmpty(this.newName)) {
      return this.$t('global.validations.required', { name: this.$t('generic.name').toString() }).toString();
    }
    if (this.newName.length > 150) {
      return this.$t('global.validations.maxLength', { length: 150 }).toString();
    }
    if (this.needsOverwrite) {
      return this.$t('clone.overwriteExplanation').toString();
    }

    return null;
  }
  public get needsOverwrite(): boolean {
    return this.newName.length > 0 && !isStringEmpty(this.nameExistsKey);
  }
  public get nameDisabled(): boolean {
    return this.needsOverwrite || this.processing;
  }
  public get okToSubmit(): boolean {
    return !isStringEmpty(this.newName) && this.newName !== this.originReport.name;
  }
  public get okayButton(): string {
    const tag = this.needsOverwrite ? 'clone.overwriteButton' : 'generic.save';
    return this.$t(tag).toString();
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public async saveReport() {
    if (this.originReport != null) {
      // reset flags
      this.nameExistsKey = null;

      // check if report name exists
      const exists = reports.reportsList.find((r) => r.name === this.newName);
      // if it does, then ask for overwrite
      if (exists != null) {
        this.nameExistsKey = exists.key;
      } else {
        // otherwise create new report
        this.processing = true;
        await reports.cloneReport({ reportKey: this.originReport.key, newName: this.newName });
        this.onClose();
      }
    }
  }
  public async overwriteReport() {
    if (this.originReport != null) {
      this.processing = true;
      await reports.copyReportIntoAnotherOne({ fromKey: this.originReport.key, toKey: this.nameExistsKey });
      this.onClose();
    }
  }
  // Event Methods
  public onSubmit() {
    if (this.needsOverwrite) {
      this.overwriteReport();
    } else {
      this.saveReport();
    }
  }
  public onClose() {
    this.processing = false;
    this.emitClose(false);
  }
  // Watchers
  @Watch('originReport')
  public originReportChanged() {
    const name = this.originReport?.name ?? '';
    this.newName = this.$t('clone.prefix', { name }).toString();
  }
  // Emitters
  @Emit('close')
  public emitClose(valueEmit: boolean) {}
}
