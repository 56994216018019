









































































// Libraries
import { Vue, Component } from 'vue-property-decorator';
import HelperMethods from '@/shared/helper-methods';
import { ReportRangeType } from '@/enums/report-enums';
import DateTimePicker from '@/components/shared/DateTimePicker.vue';
import reports from '@/store/reports';
import { IReportViewModel } from '@/view-models/reports';
import { DateRange, IDateRange, IReportTimeRangeSetting } from '@/view-models/report-time-model';
import { ISelectItem } from '../types/select-box';
// View Models
// Components
@Component({
  name: 'report-time-frame-section',
  components: {
    DateTimePicker
  }
})
export default class ReportTimeFrameSection extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX
  // Properties
  private selected: string = 'timeRange';
  public selectedFrequency: string = 'Choose Frequency';
  public selectedChooseFrequency: string = '';
  // Fields
  // Getters
  public get timeOptions(): Array<ISelectItem<ReportRangeType>> {
    const values: Array<ISelectItem<ReportRangeType>> = [];
    HelperMethods.enumKeys(ReportRangeType).forEach((t: any) => {
      values.push({
        text: this.$t(`reportRangeType.${t}`,{year: this.activeReport?.specificYear}).toString(),
        value: t
      });
    });
    return values;
  }
  public get activeReport(): IReportViewModel {
    return reports.activeReport;
  }
  public get reportTimeSettings(): IReportTimeRangeSetting {
    return reports.reportTimeSettings;
  }
  public get rangeStart(): string {
    return this.reportTimeSettings?.range?.fromDate;
  }
  public get rangeEnd(): string {
    return this.reportTimeSettings?.range?.toDate;
  }
  private get name(): string {
		return this.activeReport != null ? this.activeReport.name : '';
	}
  private set name(value: string) {
    reports.activeReport.name = value;
  }
  public get selectedTimePeriod(): ReportRangeType {
    return this.activeReport?.rangeType;
  }
  public set selectedTimePeriod(value: ReportRangeType) {
    reports.activeReport.rangeType = value;
  }
  public get selectedSpecificYear(): number {
    return this.activeReport?.specificYear;
  }
  public set selectedSpecificYear(value: number) {
    reports.activeReport.specificYear= value;

    reports.updateRangeFromReportRangeType();
  }
  // Lifecycle Handlers
  private availableYears: {value: number, text: number}[] = [];
  public change(value: number) {
  	this.$emit('input', value);
  }
  public get selectedRange(): string {
    if (this.activeReport != null) {
      const type = this.activeReport.rangeType;
      switch (type) {
        case ReportRangeType.Custom:
        return this.selected = 'customRange';
        case ReportRangeType.SpecificYear:
        return this.selected = 'specificYear';
        default:
          return this.selected = 'timeRange';
      }
    }
    return this.selected;
  }
  private created(): void {
    const currentYear = new Date().getFullYear();
  	const displayYearsBack: number = 7;
  	for (let i = 1; i <= displayYearsBack; i++) {
  		this.availableYears.push({ value: currentYear - i, text: currentYear - i } as {value: number, text: number});
  	}
  }
  // beforeCreate(): void {}
  private mounted(): void {
    const type = this.activeReport?.rangeType;
    if (type && this.timeOptions && this.timeOptions.length) {
      const timeOption = this.timeOptions.find((to) => to.value === type);
      this.onTimeRangeChange(timeOption?.value ?? this.timeOptions[0]?.value);
    }
  }
  // beforeMount(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public onStartRangeChange(date: Date | moment.Moment) {
    this.triggerRangeChange({
      fromDate: HelperMethods.asDate(date).toString(),
      toDate: this.rangeEnd
    });
  }
  public onEndRangeChange(date: Date | moment.Moment) {
    this.triggerRangeChange({
      fromDate: this.rangeStart,
      toDate: HelperMethods.asDate(date).toString()
    });
  }
  public async onTimeRangeChange(option: ReportRangeType) {
    await reports.setReportRangeType(option);
  }

  public triggerRangeChange(range: IDateRange) {
    const newRange: IDateRange = DateRange.prepareRangeForServer(range);
    reports.updateReportTimeRange(newRange);
  }

  public updateSelectedRange(option: string) {
    switch (option) {
      case 'specificYear':
        this.onTimeRangeChange(ReportRangeType.SpecificYear);
        break;

      case 'customRange':
        this.onEndRangeChange(new Date());
        this.onTimeRangeChange(ReportRangeType.Custom);
        break;
    }
  }
  // Event Methods
  // Watchers
  // Emitters
}
