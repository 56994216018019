






































// Libraries
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { newGuid } from '@/shared/string-utils';
// View Models
import { IGroupedReportVariables, IReportVariableViewModel } from '@/view-models/reports';
// Components
import SelectedVariables from './SelectedVariables.vue';
// Stores
import reports from '@/store/reports';

@Component({
  name: 'grouped-selected-variables',
  components: {
    SelectedVariables,
  },
})
export default class GroupedSelectedVariables extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public reportVariableGroup!: IGroupedReportVariables;

  // Private Methods
  private toggleOpen(reportVariableGroup: IGroupedReportVariables): void {
    return reports.toggleReportVariableGroup(reportVariableGroup.key);
  }

  public copyVariable(reportVariable: IReportVariableViewModel): IReportVariableViewModel {
    return reports.getReportVariableCopy(reportVariable);
  }

  public duplicate(reportVariable: IReportVariableViewModel): void {
    const copy: IReportVariableViewModel = this.copyVariable(reportVariable);
    reportVariable.key = newGuid();
    reports.addReportVariable(copy);
    reports.reorderReportVariables();
  }

  public removeSelectedVariable(reportVariable: IReportVariableViewModel): void {
    reports.removeReportVariable({
      reportVariableGroupKey: this.reportVariableGroup.key,
      reportVariableKey: reportVariable.key
    });
    reports.updateSelectedVariableCount({ value: reportVariable.dataRefs?.length, remove: true });
    reports.reorderReportVariables();
  }
}
