


































































// Libraries
import Vue from 'vue';
import { Component, Prop, Ref } from 'vue-property-decorator';
import { elementId } from '@/shared/component-utils';
// View Models
// Components
import ObjectSearch from '@/components/shared/ObjectSearch.vue';
import ReportVariablesTreeBranch from './ReportVariablesTreeBranch.vue';
import Loading from '@/components/common/Loading.vue';
// Store
import reportVariablesTree from '@/store/report-variables-tree';
import { IReportVariableNode } from '@/view-models/report-variables-tree/report-variable-node';
import ReportVariablesTreeHeader from '@/components/reportVariables/ReportVariablesTreeHeader.vue';
import { isStringEmpty } from '@/shared/string-utils';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  name: 'report-variables-tree',
  components: {
    ConfirmationModal,
    ReportVariablesTreeBranch,
    ReportVariablesTreeHeader,
    ObjectSearch,
    Loading,
  },
})
export default class ReportVariablesTree extends Vue {
  // Refs
  @Ref('reportVariablesTreeSearch')
  public reportVariablesTreeSearch: ObjectSearch;

  // Props
  @Prop({ default: false })
  public isLoadingReport: boolean;

  // Getters
  public get isSearching(): boolean {
    return !isStringEmpty(this.reportVariablesTreeSearch?.searchString);
  }
  public get isLoadingVariablesTrees(): boolean {
    return reportVariablesTree.isLoadingReportVariablesTrees;
  }

  public get isAssetSelected(): boolean {
    return reportVariablesTree.selectedAsset != null;
  }

  public get hasAssets(): boolean {
    return reportVariablesTree.assets?.length > 0;
  }

  public get rootNodeKey(): string {
    return reportVariablesTree.rootNodeKey || '';
  }

  public get leafNodes(): Array<IReportVariableNode> {
    return reportVariablesTree?.flattenedVariableNodes?.filter((node) => node.isLeaf) || [];
  }

  get results(): any[] {
    return reportVariablesTree.searchedNodeKeys || [];
  }

  set results(values: any[]) {
    if (values) {
      reportVariablesTree.setSearchedNodeKeys(values.map((value) => value.key));
      reportVariablesTree.closeAllNodes();
      if (this.isSearching) {
        values.forEach((value) => reportVariablesTree.openParentNodes(value.key));
      }
    }
  }

  public get header(): string {
    return this.$t(`report.modal.confirmation.header`).toString();
  }

  public get message(): string {
    return this.$t(`report.modal.confirmation.message`).toString();
  }

  // Properties
  public showEmptyResults: boolean = false;
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
  public objectSearchKey = 1;
  public showWarning: boolean = false;

  // Lifecycle Handlers
  public beforeDestroy() {
    reportVariablesTree.closeAllNodes();
    reportVariablesTree.setSearchedNodeKeys([]);
  }
  // Watchers
  // Helper Methods
  private activateWarning(): void {
    this.showWarning = true;
  }
  private resetShowWarning(): void {
    this.showWarning = false;
  }
}
