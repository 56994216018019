import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IUserViewModel } from '@/view-models/user/user-view-models';
import { Nil } from '@/shared/types';
import store from '.';
import { UserManagementService } from '@/services/user-management-service';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';
import CustomerPreferenceService from '@/services/customer-preference-service';

export interface IUserModule {
  // State
  currentUser: Nil<IUserViewModel>;
  measurementSystemOptions: IAvailableMeasurementSystemsViewModel[];

  // Getters
  getCurrentUser(): IUserViewModel;

  // Mutations
  setCurrentUser(user: Nil<IUserViewModel>): void;
  setMeasurementSystemOptions(options: IAvailableMeasurementSystemsViewModel[]): void;

  // Actions
  getUser(): Promise<IUserViewModel>;
  getMeasurementSystemOptions(customerKey: string): Promise<IAvailableMeasurementSystemsViewModel[]>;
}

@Module({ dynamic: true, store, name: 'user'})
export class UserModule extends VuexModule implements IUserModule {
  // State
  public currentUser: Nil<IUserViewModel> = null;
  public measurementSystemOptions: IAvailableMeasurementSystemsViewModel[] = [];

  // Getters
  public getCurrentUser(): IUserViewModel {
    return this.currentUser;
  }

  public get preferredMeasurementSystem(): string {
    const preferredSystem: IAvailableMeasurementSystemsViewModel =
        this.measurementSystemOptions.filter((option) => option.preferredFlag)[0];
    return preferredSystem.systemKey;
  }

  // Mutations
  @Mutation
  public setCurrentUser(user: Nil<IUserViewModel>): void {
    this.currentUser = user;
  }

  @Mutation
  public setMeasurementSystemOptions(options: IAvailableMeasurementSystemsViewModel[]): void {
    this.measurementSystemOptions = Object.assign([], options);
  }

  @Action({ rawError: true })
  public async getUser(): Promise<IUserViewModel> {
    const user: IUserViewModel = await UserManagementService.factory().getCurrentUser();
    this.setCurrentUser(user);
    return user;
  }

  @Action({ rawError: true })
  public async getMeasurementSystemOptions(customerKey: string): Promise<IAvailableMeasurementSystemsViewModel[]> {
    const measurementSystems: IAvailableMeasurementSystemsViewModel[] =
        await CustomerPreferenceService.factory().getAvailableMeasurementSystems(customerKey);
    this.setMeasurementSystemOptions(measurementSystems);
    return measurementSystems;
  }
}

export default getModule(UserModule, store);

