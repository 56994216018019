interface String {
  /**
   * Returns a string with last comma replaced with given string
   * @param replaceString the string to replace comma with
   */
  replaceLastCommaWith(replaceString: string): String;
}

String.prototype.replaceLastCommaWith = function(replaceString: string) {
  const index = this.lastIndexOf(',');
  return index > -1 ? this.substring(0, index) + replaceString + this.substring(index + 1) : this;
};
