

























// Libraries
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
// View Models
import { IReportVariableNode } from '@/view-models/report-variables-tree/report-variable-node';
// Store
import reports from '@/store/reports';
import reportVariablesTree from '@/store/report-variables-tree';
import ReportHelper from '@/shared/report-helper';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  name: 'report-variables-tree-leaf',
  components: {
    ConfirmationModal
  },
})
export default class ReportVariablesTreeLeaf extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public nodeKey!: string;

  // Getters
  get node(): IReportVariableNode {
    return reportVariablesTree.variableNodeMap.get(this.nodeKey);
  }

  get nodeName(): string {
    return this.node.displayName;
  }

  get hasData(): boolean {
    return this.node.containsReportData;
  }

  // Event Methods
  public async addAsReportVariable(): Promise<void> {
    const length = this.node.dataRefs?.length;
    if (ReportHelper.isExceedVariableLimit(length, reports.selectedVariableCount)) {
      this.showWarning();
    } else {
      reports.addReportVariable(this.node.toReportVariableViewModel());
      reports.updateSelectedVariableCount({ value: length });
    }
  }

  // Emitters
  @Emit('show-warning')
  public showWarning(): void {}
}
