import { Prop, Mixins } from 'vue-property-decorator';
import {
  AxisLabelsFormatterContextObject,
  Chart3dOptions,
  LegendOptions,
  Options,
  Point,
  TitleOptions,
  TooltipFormatterContextObject,
  YAxisOptions,
  YAxisPlotBandsOptions,
  YAxisPlotLinesOptions,
  XAxisPlotLinesOptions, PositionObject, Tooltip, TooltipPositionerCallbackFunction,
} from 'highcharts';
import { ReportAxis, ReportRangeType, SeriesTypes } from '@/enums/report-enums';
import {
  IReportViewModel,
  IReportVariableViewModel,
  IReportDataViewModel,
  ReportVariableData,
} from '@/view-models/reports';
import AppReady from '@/components/mixins/app-ready';
import HelperMethods from '@/shared/helper-methods';
import ReportHelper, { ReportSeriesOptionsType } from '@/shared/report-helper';
import SeriesColors from '@/shared/series-colors';
import { IDateRange } from '@/view-models/report-time-model';
import { HighchartFormatHelper } from '@/shared/i18n/highcharts-format-helper';
import { ReportTimeHelper } from '@/shared/time-helper-methods';
import { alphabeticSorter, distinctArray } from '@/shared/array-utils';
import { ReportVariableState } from '@/enums/report-variable-state';
import { TooltipPositionerPointObject } from 'highcharts/highcharts.src';

export default abstract class BaseChartComponent extends Mixins(AppReady) {
  private static readonly DEFAULT_MAX_Y = 100.0;

  public flags: { [key: string]: boolean };

  @Prop({ default: false })
  public square: boolean;
  @Prop({ default: false })
  public squareMd: boolean;
  @Prop({ default: 80 })
  public marginLeft: number;

  protected supportsDrillDown: boolean = true;
  protected chartWidth: number = undefined;
  protected chartHeight: number = undefined;
  protected isChartWidget: boolean = false;
  protected hiddenSeries: ReportSeriesOptionsType[] = [];
  private chartWidgetLabelFontSize: string = '.7rem';

  protected abstract getExecutedReport(): IReportViewModel;
  protected abstract getActiveReport(): IReportViewModel;
  protected abstract getTitleOptions(): TitleOptions;
  protected abstract getReportData(): Array<IReportDataViewModel>;
  protected abstract drillDown(clickedPoint: Point): void;
  protected abstract tooltipFormatter(): (this: TooltipFormatterContextObject) => string;

  protected get plotBands(): Array<YAxisPlotBandsOptions> {
    return [];
  }

  protected get yPlotLines(): Array<YAxisPlotLinesOptions> {
    return [];
  }
  protected get xPlotLines(): Array<XAxisPlotLinesOptions> {
    return [];
  }

  protected get legendOptions(): LegendOptions {
    return {
      enabled: false,
      itemStyle: {
        color: '#ffffff',
      },
    };
  }

  protected get highchartFormatHelper(): HighchartFormatHelper {
    return new HighchartFormatHelper(this.$i18n);
  }

  protected get chartOptions(): Options {
    const vueInstance: BaseChartComponent = this;
    const seriesList = this.transformStoreSeriesData();
    const [xRangeMin, xRangeMax] = this.getXAxisRange();
    const options: Options = {
      time: {
        timezoneOffset: 0,
      },
      exporting: { enabled: false },
      chart: {
        animation: false,
        backgroundColor: 'transparent',
        width: this.getChartWidth(),
        height: this.getChartHeight(),
        marginLeft: this.marginLeft,
        reflow: true,
        options3d: this.get3dOptions(),
        renderTo: 'container',
        resetZoomButton: {
          theme: {
            fill: 'var(--primary-100)',
            stroke: 'var(--primary-100)',
            r: 0,
            style: {
              color: 'var(--dark-600)',
              fontWeight: 'bold',
            },
            states: {
              hover: {
                fill: 'var(--primary-300)',
                stroke: 'var(--primary-300)',
              },
            },
          },
        },
        type: 'column',
        zoomType: 'x',
      },
      noData: {
        style: {
          color: 'var(--gray-500)',
        },
      },
      lang: {
        noData: this.$t('chart.noData').toString(),
      },
      credits: {
        enabled: false,
      },
      legend: this.legendOptions,
      plotOptions: {
        column: {
         depth: 25,
         pointPadding: 0.0,
         maxPointWidth: 3,
         groupPadding: 0.45
        },
        areaspline: {
          gapSize: 1.0,
        },
        area: {
          gapSize: 1.0,
        },
        series: {
          cropThreshold: 100000,
          animation: false,
          gapSize: 1.0,
          cursor: 'pointer',
          connectEnds: false,
          marker: {
            enabled: false,
          },
          point: {
            events: {
              click(this: Point) {
                if (vueInstance.supportsDrillDown) {
                  vueInstance.drillDown(this);
                }
              },
            },
          },
          stacking: undefined,
          states: {
            hover: {
              brightness: -0.1,
            },
          },
        },
      },
      series: seriesList,
      title: this.getTitleOptions(),
      tooltip: {
        shared: true,
        useHTML: true,
        outside: true,
        animation: false,
        formatter: this.tooltipFormatter(),
        positioner: this.buildTooltipPositioner(),
        backgroundColor: 'var(--tool-tip-background-highlight)',
        borderColor: 'var(--tool-tip-background-highlight)',
        borderRadius: 6,
      },
      xAxis: {
        tickInterval: this.xAxisTickInterval(),
        min: xRangeMin,
        max: xRangeMax,
        gridLineWidth: 1,
        gridLineColor: 'transparent',
        plotLines: this.xPlotLines,
        minPadding: 0,
        maxPadding: 0,
        labels: {
          formatter(this: AxisLabelsFormatterContextObject<number>): string {
            const report: IReportViewModel = vueInstance.getExecutedReport();
            const date: Date = new Date(this.value);
            const dateValue: string = vueInstance.highchartFormatHelper.date(
              date,
              report.dateRange
            );
            return `<div title="${dateValue}" class="cut-off" style="max-width: 3.5rem;">${dateValue}</div>`;
          },
          useHTML: this.isChartWidget,
          rotation: -45,
          style: {
            color: 'var(--white)',
            fontSize: this.isChartWidget ? this.chartWidgetLabelFontSize : '10px',
          },
        },
        type: 'datetime',
      },
      yAxis: this.yAxisOptions(seriesList),
    };

    return options;
  }
  public xAxisTickInterval(): number {
    return null;
  }
  public fixTimeRangeLength(time: number) {
    if (time && time.toString().length === 10) {
      time = time * 1000;
    }

    return time;
  }
  public getXAxisRange(): number[] {
    const report = this.getExecutedReport();
    if (report == null) {
      return [null, null];
    } else {
      const dateRange: IDateRange = this.getExecutedReport().dateRange;
      let rangeMin = dateRange.fromDate
        ? this.fixTimeRangeLength(HelperMethods.asDate(dateRange.fromDate).getTime())
        : 0;
      let rangeMax = dateRange.toDate ? this.fixTimeRangeLength(HelperMethods.asDate(dateRange.toDate).getTime()) : 0;
      if (this.getReportData()) {
        const minMaxDataValues: number[] = this.getMinMaxDataValues();
        // If we have values, check against the range values.
        // rangeMax = this.adjustEndForTotalBy();
        if (minMaxDataValues.length > 1) {
          const minValue: number = this.fixTimeRangeLength(minMaxDataValues[0]);
          const maxValue: number = this.fixTimeRangeLength(minMaxDataValues[minMaxDataValues.length - 1]);
          // If the min/max is outside of the range values, update them to encompass the values.
          rangeMin = minValue < rangeMin ? minValue : rangeMin;
          rangeMax = maxValue > rangeMax ? maxValue : rangeMax;
        } else {
          rangeMin = null;
          rangeMax = null;
        }
        return [rangeMin, rangeMax];
      } else {
        return [rangeMin, rangeMax];
      }
    }
  }
  public getMinMaxDataValues(): number[] {
    let minMaxDataValues: number[] = [];
    // Collect min/max date time stamps
    (this.getReportData() || []).forEach((d: IReportDataViewModel) => {
      if ((d?.data?.length ?? 0) > 0) {
        minMaxDataValues.push(d.data[0].timestamp);
        minMaxDataValues.push(d.data[d.data.length - 1].timestamp);
      }
    });
    if (minMaxDataValues.length <= 0) {
      const rangeType: ReportRangeType = this.getExecutedReport().rangeType;
      const dateRange = ReportTimeHelper.calculateDateRangeFromRangeType(rangeType);
      minMaxDataValues.push(new Date(dateRange.fromDate).getTime());
      minMaxDataValues.push(new Date(dateRange.toDate).getTime());
    }
    minMaxDataValues = distinctArray<number>(minMaxDataValues);
    return minMaxDataValues.sort();
  }
  // Lifecycle Handlers
  // public created(): void { }
  // beforeCreate(): void {}
  // beforeMount(): void {}
  // public mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private buildTooltipPositioner(): TooltipPositionerCallbackFunction {
    return function(
        this: Tooltip,
        labelWidth: number,
        labelHeight: number,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        point: (Point|TooltipPositionerPointObject)
    ): PositionObject {
      const chartPos = this.chart.pointer.getChartPosition();
      return {
        x: chartPos.left + ((this.chart.chartWidth - labelWidth) / 2),
        y: Math.max(chartPos.top - labelHeight - 15, 15) // margin top, but don't let it go off screen
      };
    };
  }
  protected getChartHeight(): string | number {
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (this.square) {
      return '80%';
    } else if (this.squareMd) {
      const minusHeight = (height * 25) / 100;
      return height - minusHeight;
    } else {
      return this.chartHeight;
    }
  }

  protected getChartWidth(): string | number {
    // const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (this.squareMd) {
      return null;
    } else {
      return this.chartWidth;
    }
  }

  private get3dOptions(): Chart3dOptions {
    return {
      enabled: false,
      alpha: 15,
      beta: 15,
      depth: 50,
      viewDistance: 25,
    };
  }
  private getYAxisOptions(axis: number): YAxisOptions {
    return {
      gridLineColor: '#ffffff',
      gridLineDashStyle: 'Dot',
      gridLineWidth: this.getExecutedReport().showGridLines ? 1 : 0,
      labels: {
        format: '',
        style: {
          fontSize: this.isChartWidget ? this.chartWidgetLabelFontSize : null,
          color: 'var(--white)',
        },
      },
      title: {
        text: '',
      },
      plotBands: axis === 0 ? this.plotBands : [],
      plotLines: axis === 0 ? this.yPlotLines : [],
      showEmpty: true,
    };
  }

  private yAxisOptions(seriesList: Array<ReportSeriesOptionsType>): Array<YAxisOptions> {
    const left: YAxisOptions = Object.assign({}, this.getYAxisOptions(0));
    const vueInstance: BaseChartComponent = this;
    left.labels.formatter = function(this: AxisLabelsFormatterContextObject<number>) {
      return vueInstance.highchartFormatHelper.abbreviateLargeNumber(this.value);
    };
    left.labels.useHTML = this.isChartWidget;
    left.id = ReportAxis.Left;

    const right: YAxisOptions = Object.assign({}, this.getYAxisOptions(1));
    right.labels.formatter = function(this: AxisLabelsFormatterContextObject<number>) {
      return vueInstance.highchartFormatHelper.abbreviateLargeNumber(this.value);
    };
    right.labels.useHTML = this.isChartWidget;
    right.id = ReportAxis.Right;
    right.opposite = true;

    const noData: boolean = seriesList.every(
      (series: ReportSeriesOptionsType) => series.data == null || series.data.length <= 0
    );
    if (noData) {
      left.min = right.min = 0;
      left.max = right.max = BaseChartComponent.DEFAULT_MAX_Y;
      return [left, right];
    }

    left.max = right.max = undefined;
    const minY0 = ReportHelper.minYValue(seriesList, 0);
    const minY1 = ReportHelper.minYValue(seriesList, 1);
    left.min = minY0;
    right.min = minY1;
    return [left, right];
  }

  // Helper Methods
  private transformStoreSeriesData(): Array<ReportSeriesOptionsType> {
    const reportData: Array<IReportDataViewModel> = this.getReportData() || [];
    const series: Array<ReportSeriesOptionsType> = [];
    const executedReportVariables = this.getExecutedReport().reportVariables || [];
    const activeReportVariables = this.getActiveReport().reportVariables || [];

    reportData.forEach((variableData: IReportDataViewModel) => {
      if (variableData == null) {
        return;
      }
      const executedVariable: IReportVariableViewModel = executedReportVariables.find(
        (ri: IReportVariableViewModel) => ri.key === variableData.key
      );
      const activeVariable: IReportVariableViewModel = activeReportVariables.find(
        (ri: IReportVariableViewModel) => ri.key === variableData.key
      );
      const options: ReportSeriesOptionsType = {
        id: variableData.key,
        borderColor: '',
        color: '',
        data: (variableData.data ?? []).map((dataSet: ReportVariableData) => [dataSet.timestamp, dataSet.data]),
        name: variableData.name == null ? '' : variableData.displayValue,
        type: SeriesTypes.Line.toLocaleLowerCase() as any,
        yAxis: 0,
        connectNulls: true,
        state: variableData?.state ?? ReportVariableState.Ok,
        mergedDetails: variableData.mergedDetails,
        reportVariable: activeVariable
      };
      const variable = Object.assign({}, executedVariable, activeVariable || {});
      if (Object.keys(variable).length > 0) {
        options.yAxis = variable.axis === ReportAxis.Left ? 0 : 1;
        options.type = (variable.chartType === SeriesTypes.Unknown ? SeriesTypes.Line : variable.chartType)
          .toString()
          .toLocaleLowerCase() as any;
        options.name = this.createSeriesLabel(variableData, variable);
        options.assetKey = variable.assetKey;
      }
      const isHidden = this.hiddenSeries.find((s1) => s1.id === options.id);

      options.visible = (
        ((variableData?.data?.length ?? 0) > 0) && variableData.state === ReportVariableState.Ok && !isHidden
      ) || !isHidden;

      series.push(options);
    });

    if (series.length === 0) {
      // So it shows a Y axis.
      series.push({
        id: HelperMethods.newGuid(),
        borderColor: 'white',
        color: 'white',
        data: [],
        name: '',
        type: SeriesTypes.Line.toLocaleLowerCase() as any,
        yAxis: 0,
        state: ReportVariableState.Ok,
        reportVariable: null
      });
      return series;
    }

    series.sort(alphabeticSorter((s) => s.name));

    series.forEach((options: ReportSeriesOptionsType, index: number) => {
      const color = SeriesColors.getColor(index);
      options.color = color;
      options.borderColor = color;
    });

    return series;
  }

  private createSeriesLabel(dataVariable: IReportDataViewModel, variable: IReportVariableViewModel) {
    const displayName = variable.displayName ? variable.displayName : dataVariable.displayValue;
    return displayName;
  }

  // Watchers
}
