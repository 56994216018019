import { UnitOfMeasurementEnum } from '@/enums/unit-of-measurement';
import { MeasurementTypesEnum } from '@/enums/variables';

export function getUoMBasedOnMeasurementType(measurementType: MeasurementTypesEnum): UnitOfMeasurementEnum[] {
  const result: UnitOfMeasurementEnum[] = [];

  switch (measurementType) {
    case MeasurementTypesEnum.Pressure:
    case MeasurementTypesEnum.FuelPressure:
      result.push(
        UnitOfMeasurementEnum.Pa,
        UnitOfMeasurementEnum.psi,
        UnitOfMeasurementEnum.psig,
        UnitOfMeasurementEnum.psia,
        UnitOfMeasurementEnum.inH2O,
        UnitOfMeasurementEnum.mmH2O,
        UnitOfMeasurementEnum.inHg,
        UnitOfMeasurementEnum.bar,
        UnitOfMeasurementEnum.barg,
        UnitOfMeasurementEnum.mbar,
        UnitOfMeasurementEnum.mbarg,
        UnitOfMeasurementEnum.kgcm2
      );
      break;
    case MeasurementTypesEnum.Temperature:
      result.push(
        UnitOfMeasurementEnum.degF,
        UnitOfMeasurementEnum.degC,
        UnitOfMeasurementEnum.degR,
        UnitOfMeasurementEnum.degK
      );
      break;
    case MeasurementTypesEnum.Distance:
      result.push(
        UnitOfMeasurementEnum.ft,
        UnitOfMeasurementEnum.in,
        UnitOfMeasurementEnum.m,
        UnitOfMeasurementEnum.mm
      );
      break;
    case MeasurementTypesEnum.FlowRate:
      result.push(
        UnitOfMeasurementEnum.kgs,
        UnitOfMeasurementEnum.kghr,
        UnitOfMeasurementEnum.lbms,
        UnitOfMeasurementEnum.lbmhr,
        UnitOfMeasurementEnum.klbmhr,
        UnitOfMeasurementEnum.lbs
      );
      break;
    case MeasurementTypesEnum.Concentration:
      result.push(
          UnitOfMeasurementEnum.ppm,
          UnitOfMeasurementEnum.percentmol
      );
      break;
    case MeasurementTypesEnum.Energy:
      result.push(
        UnitOfMeasurementEnum.W,
        UnitOfMeasurementEnum.MW,
        UnitOfMeasurementEnum.Gcalhr,
        UnitOfMeasurementEnum.MMBTUhr,
        UnitOfMeasurementEnum.Mmkcalhr,
        UnitOfMeasurementEnum.BTUh
      );
      break;
    case MeasurementTypesEnum.O2MoleFraction:
      result.push(
        UnitOfMeasurementEnum.DRY,
        UnitOfMeasurementEnum.WET,
        UnitOfMeasurementEnum.Percent,
        UnitOfMeasurementEnum.Fraction
      );
      break;
    case MeasurementTypesEnum.FuelPropertyEnergy:
      result.push(
          UnitOfMeasurementEnum.kCalkg,
          UnitOfMeasurementEnum.BTUlbm,
          UnitOfMeasurementEnum.Jkg
      );
      break;
    case MeasurementTypesEnum.Numeric:
      result.push(UnitOfMeasurementEnum.Fraction, UnitOfMeasurementEnum.Percent);
      break;
    case MeasurementTypesEnum.Boolean:
      result.push(UnitOfMeasurementEnum.Bool);
      break;
    case MeasurementTypesEnum.Text:
      result.push(UnitOfMeasurementEnum.String);
      break;
    case MeasurementTypesEnum.Area:
      result.push(
          // Leaving off sqin and sqcm for now until requested later
          UnitOfMeasurementEnum.sqft,
          // UnitOfMeasurementEnum.sqin,
          UnitOfMeasurementEnum.sqm,
          // UnitOfMeasurementEnum.sqcm
      );
      break;
    case MeasurementTypesEnum.Unknown:
    default:
      result.push(UnitOfMeasurementEnum.Default);
      break;
  }

  return result;
}
