export default class SeriesColors {
  private static readonly SERIES_COLORS: string[] = [
    // From /d/#/console/16088141/379423106/preview
    '#B1D4F8',
    '#0074FF',
    '#6046B8',
    '#D55E7B',
    '#29A386',
    '#9576BC',
    '#B6DDC8',
    '#669642',
    '#FCDCA5',
    '#CFC9E7',
    '#E87441',
    '#6999C5',
    '#FFD1DE',
    '#9DFEDE',
    '#FBA0A0',
    '#A8D3DD',
    '#F6CBB8',
    '#CBD7F2',
    '#E3FFF9',
    '#DAD7D2',
    '#ADEAFD',
    '#CDA792',
    '#DDF3AE'
  ];

  public static getColor(index: number): string {
    const n = SeriesColors.SERIES_COLORS.length;
    return SeriesColors.SERIES_COLORS[index % n];
  }
}
