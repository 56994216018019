


























































// Libraries
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
// import { NoCache } from '@/shared/decorators';
import { alphabeticSorter } from '@/shared/array-utils';
// Components
import ReportVariablesTreeLeaf from './ReportVariablesTreeLeaf.vue';
import Loading from '@/components/common/Loading.vue';
// Store
import reportVariablesTree from '@/store/report-variables-tree';
import reports from '@/store/reports';
// view-models
import { IReportVariableNode } from '@/view-models/report-variables-tree/report-variable-node';
import ReportHelper from '@/shared/report-helper';
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue';

@Component({
  name: 'report-variables-tree-branch',
  components: {
    ReportVariablesTreeLeaf,
    Loading,
    ConfirmationModal
  },
})
export default class ReportVariablesTreeBranch extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public nodeKey!: string;

  private get isSearchInclusive(): boolean {
    return reportVariablesTree.doesContainSearchedNode(this.node);
  }

  private get node(): IReportVariableNode {
    return reportVariablesTree.variableNodeMap.get(this.nodeKey);
  }

  private get isLoading(): boolean {
    return reportVariablesTree.isLoadingReportVariableDataNodeLeaves(this.node.key);
  }

  private get childNodes(): string[] {
    return this.node.children.sort(alphabeticSorter<string>(
        (nodeKey) => reportVariablesTree.variableNodeMap.get(nodeKey).displayName)
    );
  }

  public get header(): string {
    return this.$t(`report.modal.confirmation.header`).toString();
  }

  public get message(): string {
    return this.$t(`report.modal.confirmation.message`).toString();
  }

  // Private Methods
  private async addAsReportVariables() {
    if (this.node.isDataNode) {
      const childNodes = this.node.children.map((childKey) => reportVariablesTree.variableNodeMap.get(childKey));
      const variableCount = childNodes.reduce((count, child) => count + child.dataRefs?.length, 0);
      if (ReportHelper.isExceedVariableLimit(variableCount, reports.selectedVariableCount)) {
        this.showWarning();
      } else {
        for (const child of childNodes) {
          reports.addReportVariable(child.toReportVariableViewModel());
          reports.updateSelectedVariableCount({ value: child.dataRefs?.length });
        }
      }
    }
  }

  private toggleOpenBranch() {
    reportVariablesTree.toggleOpenNode(this.node.key);
  }

  // Emitters
  @Emit('show-warning')
  public showWarning(): void {}

}
