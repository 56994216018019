
































// Libraries
import { Component, Vue, Prop } from 'vue-property-decorator';
import { isStringEmpty } from '@/shared/string-utils';
import { eventBus } from '@/shared/event-bus';
// View Models
import { IReportViewModel } from '@/view-models/reports';
// Components
import IconButton from '@/components/dsm/IconButton.vue';
import LabelButton from '@/components/dsm/LabelButton.vue';
import SaveReportModal from '@/components/shared/SaveReportModal.vue';
// Stores
import reports from '@/store/reports';

@Component({
  name: 'report-view-actions-bar',
  components: {
    IconButton,
    LabelButton,
    SaveReportModal
  }
})
export default class ReportViewActionsBar extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public readonly report!: IReportViewModel;
  // Refs
  // VUEX
  // Properties
  public showSaveReport: boolean = false;

  // Fields
  // Getters
  public get reportName(): string {
    return this.report?.name ? this.report.name : this.$t('report.unsavedReport').toString();
  }
  public get hasKey(): boolean {
    return !isStringEmpty(this.report?.key);
  }
  public get isFavorite(): boolean {
    return this.report.isFavorite === true;
  }
  public get isSaveDisabled(): boolean {
    return this.report.key == null && !this.report.isDirty();
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public onEditReport() {
    this.$router.push(`/settings/${this.report.key}`);
  }
  public onReportDownload() {
    eventBus.$emit('downloadCSV');
  }
  public onFavoriteToggle() {
    if (this.report.isFavorite) {
      reports.unfaveReport(this.report.key);
    } else {
      reports.faveReport(this.report.key);
    }
  }
  public onReportSave() {
    this.showSaveReport = true;
  }
  // Watchers
  // Emitters
}
