




import { Component, Vue, Prop } from 'vue-property-decorator';
import { BreadCrumbItem } from '@/view-models/breadcrumb';

@Component
export default class Breadcrumb extends Vue {
  @Prop({ required: true })
  private breadcrumbItems!: BreadCrumbItem[];
}
