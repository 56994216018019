import { Nil } from '@/shared/types';
import { AxiosInstance, AxiosRequestConfig, CancelToken } from 'axios';

export default class BaseService {
    constructor(
        private readonly http: AxiosInstance
    ) { }

    public async httpGet<T>(uri: string, query?: Record<string, any>, headers?: any): Promise<T> {
        const config: AxiosRequestConfig = {
            headers: headers || undefined,
            params: query || undefined
        };
        return (await this.http.get<T>(this.url(uri), config))?.data;
    }

    public async httpPost<T>(uri: string, payload?: any, cancelToken?: CancelToken, headers?: any, query?: Record<string, any>): Promise<T> {
        let config: AxiosRequestConfig = {
            cancelToken: cancelToken || undefined,
            headers: headers || undefined,
            params: query || undefined
        };
        return (await this.http.post<T>(this.url(uri), payload, config))?.data;
    }

    public async httpPut<T>(uri: string, payload?: any, headers?: any, query?: Record<string, any>): Promise<T> {
        const config: AxiosRequestConfig = {
            headers: headers || undefined,
            params: query || undefined
        };
        return (await this.http.put<T>(this.url(uri), payload, config)).data;
    }

    public async httpDelete<T>(uri: string, query?: Record<string, any>): Promise<T> {
        return (await this.http.delete<T>(this.url(uri), processQuery(query))).data;
    }

    protected url(uri?: string): string {
        return uri ?? '';
    }
}

function processQuery(query?: Record<string, any>): Nil<AxiosRequestConfig> {
    return query == null ? undefined : { params: query };
}
