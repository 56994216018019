import { AssignmentNodeType } from '@/enums/assignment-node-type';
import { ReportAxis, SeriesTypes } from '@/enums/report-enums';
import { AccessSettingsEnum, MeasurementTypesEnum } from '@/enums/variables';
import { newGuid } from '@/shared/string-utils';
import { IReportVariableViewModel, ReportVariableViewModel } from '../reports';
import reports from '@/store/reports';
import { UnitOfMeasurementEnum } from '@/enums/unit-of-measurement';

export interface IReportVariableNode {
  key: string;
  dataNodeKey?: string;
  name: string;
  topDownHierarchyNames: string[];
  displayName: string;
  assetKey: string;
  parentKey: string;
  customerSiteKey: string;
  isLeaf: boolean;
  isOpen?: boolean;
  isSelected?: boolean;
  isDataNode?: boolean;
  containsReportData?: boolean;
  children?: string[];
  measurementType?: MeasurementTypesEnum;

  // only applicable for running reports
  dataRefs?: string[];
  reportTableKey?: string;

  // helper methods
  toReportVariableViewModel?(): IReportVariableViewModel;
}

export class ReportVariableNode implements IReportVariableNode {
  public key: string = '';
  public dataNodeKey?: string = '';
  public name: string = '';
  public topDownHierarchyNames: string[] = [];
  public displayName: string = '';
  public assetKey: string = '';
  public parentKey: string = '';
  public customerSiteKey: string = '';
  public isLeaf: boolean = false;
  public isOpen?: boolean = false;
  public isSelected?: boolean = false;
  public isDataNode?: boolean = false;
  public children?: string[] = [];
  public dataRefs?: string[] = [];
  public reportTableKey?: string = '';
  public measurementType?: MeasurementTypesEnum = MeasurementTypesEnum.Unknown;

  constructor(reportVariableNode?: IReportVariableNode) {
    if (reportVariableNode != null) {
      Object.assign(this, reportVariableNode);
    }
  }

  public get currentMeasurementSystem(): string {
    return reports.currentMeasurementSystem;
  }

  public toReportVariableViewModel(): IReportVariableViewModel {
    const hierarchyPathName = this.topDownHierarchyNames
      ? this.topDownHierarchyNames.slice(0, this.topDownHierarchyNames.length - 1).join('/')
      : this.displayName;
    const reportVariable: IReportVariableViewModel = new ReportVariableViewModel({
      key: newGuid(),
      nodeKey: this.dataNodeKey,
      assetKey: this.assetKey,
      displayName: this.displayName,
      topDownHierarchyPathName: hierarchyPathName,
      axis: ReportAxis.Left,
      sortOrder: 1,
      chartType: SeriesTypes.Line,
      dataRefs: this.dataRefs,
      tempusTableKey: this.reportTableKey,
      measurementType: this.measurementType,
      unitOfMeasurement: UnitOfMeasurementEnum.Default
    });

    return reportVariable;
  }
}

export interface IAssignmentTreeNodeViewModel {
  key: string;
  name: string;
  parentKey?: string;
  customerSiteKey?: string;
  type: AssignmentNodeType;

  // client side
  hierarchyTrees?: IHierarchyTreeViewModel[];
}

export interface IHierarchyTreeViewModel {
  key: string;
  name?: string;
  parentKey: string;
  assetKey: string;
  availableNodes: IHierarchyTreeNodeViewModel[];
}

export interface IHierarchyTreeNodeViewModel {
  nodeKey: string;
  nodeName: string;
  assetKey: string;
  parentNodeKey: string;
}

export interface IReportVariablesViewModel {
  assetKey: string;
  variables: IReportAssetVariableViewModel[];
}

export interface IReportAssetVariableViewModel {
  displayName: string;
  displayValues: string[];
  dataRefs: string[];
  hasData: boolean;
  isMerged: boolean;
  manuallyUpdated: boolean;
  accessSettings: AccessSettingsEnum[];
  measurementType: MeasurementTypesEnum;
  reportTableKey: string;
  nodeKey: string;
  variableKey: string;

  // client-side
  isOpen?: boolean;
}
