import { AxiosInstance } from 'axios';
import ControllerBaseService from '@/services/common/controller-base-service';
import { http } from './common/http';
import { IAvailableMeasurementSystemsViewModel } from '@/view-models/measurement-systems-view-model';

export default class CustomerPreferenceService extends ControllerBaseService {
    constructor(axios: AxiosInstance) {
        super('dv/customerPreference', axios);
    }

    public static factory(): CustomerPreferenceService {
        return new CustomerPreferenceService(http.instance);
    }

    /**
     * Get available measurement systems
     * @param customerKey string
     * @returns {Promise<IAvailableMeasurementSystemsViewModel[]>}
     */
    public getAvailableMeasurementSystems(customerKey: string): Promise<IAvailableMeasurementSystemsViewModel[]> {
        return this.httpGet<IAvailableMeasurementSystemsViewModel[]>(`availableMeasurementSystems/${customerKey}`);
    }
}
