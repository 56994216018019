import { inParentApp } from '@/shared/from-parent';

export interface IConfigBase {
  domain: string;
  clientId: string;
  audience: string;
  redirectUriStandalone: string;
  redirectUriParent: string;
  baseApiUrl: string;
  userManagementApiUrl: string;
}

export interface IConfig extends IConfigBase {
  redirectUri: string;
}

export class Config implements IConfig {
  public domain!: string;
  public clientId!: string;
  public audience!: string;
  public redirectUriStandalone!: string;
  public redirectUriParent!: string;
  public baseApiUrl!: string;
  public userManagementApiUrl!: string;

  constructor(config: IConfig | IConfigBase) {
    Object.assign(this, config);
  }

  get redirectUri(): string {
    return inParentApp() ? this.redirectUriParent : this.redirectUriStandalone;
  }
  set redirectUri(uri: string) {
    this.redirectUriParent = uri;
    this.redirectUriStandalone = uri;
  }
}
