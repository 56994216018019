export enum EntityType {
  Asset = 'Asset',
  AssetGrouping = 'AssetGrouping',
  AssetReport = 'AssetReport',
  BurnerGrouping = 'BurnerGrouping',
  Customer = 'Customer',
  CustomerSite = 'CustomerSite',
  Dashboard = 'Dashboard',
  Heater = 'Heater',
  KESCompany = 'KESCompany',
  Organization = 'Organization',
  Product = 'Product',
  ProductSubscription = 'ProductSubscription',
  Report = 'Report',
  Tower = 'Tower',
  User = 'User',
  UserAssignment = 'UserAssignment',
  UserFavorite = 'UserFavorite',
  UserRole = 'UserRole',
  Unknown = 'Unknown'
}

export enum PermissionsEnum {
  Unknown = 'Unknown',
  CreateUser = 'CreateUser',
  ManageUsers = 'ManageUsers',
  SetUserAssignments = 'SetUserAssignments',
  ReadBurnerStatus = 'ReadBurnerStatus',
  WriteBurnerStatus = 'WriteBurnerStatus',
  ManageCustomers = 'ManageCustomers',
  ReadHeaters = 'ReadHeaters',
  CreateHeaters = 'CreateHeaters',
  ManageHeaters = 'ManageHeaters',
  DeleteLogs = 'DeleteLogs',
  ManageKESCompanies = 'ManageKESCompanies',
  ShowRecommendationOverride = 'ShowRecommendationOverride',
  KESPortalAccess = 'KESPortalAccess',
  ViewAssetDiagram = 'ViewAssetDiagram',
  CreateAssetDiagram = 'CreateAssetDiagram',
  ViewReports = 'ViewReports',
  ManageReports = 'ManageReports',
  ViewDashboards = 'ViewDashboards',
  ManageDashboards = 'ManageDashboards',
  ManagePerformance = 'ManagePerformance',
  ViewPerformance = 'ViewPerformance',
  CreateHydraulicModel = 'CreateHydraulicModel',
  ViewHydraulicModel = 'ViewHydraulicModel',
  ManageAssetConfiguration = 'ManageAssetConfiguration',
  ViewAssetConfiguration = 'ViewAssetConfiguration',
}
