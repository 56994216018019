














































































// Libraries
import '@/shared/extensions/string.extensions.ts';
import {Component, Emit, Prop, Vue} from 'vue-property-decorator';
// View Models
// Components
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
import {ISelectItem} from '@/components/types/select-box';
import {ReportAxis, SeriesTypes} from '@/enums/report-enums';
// Services
// Stores
import reports from '@/store/reports';
import {ReportVariableMergedState, ReportVariableState} from '@/enums/report-variable-state';
import {MeasurementTypesEnum} from '@/enums/variables';
import {UnitOfMeasurementEnum} from '@/enums/unit-of-measurement';
// Utilities
import {getUoMBasedOnMeasurementType} from '@/utils/uom/uom-helper';
import SeriesColors from '@/shared/series-colors';
import {IReportVariableUomModel, IReportVariableViewModel, ReportVariableViewModel} from '@/view-models/reports';

@Component({
  name: 'report-variable-controls',
  components: { SelectDropdown },
})
export default class ReportVariableControls extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public reportVariable: IReportVariableViewModel;
  @Prop({ required: true })
  public variableIndex: number;
  @Prop({ default: 'default' })
  public variant: string;

  // Fields
  public ReportVariableState = ReportVariableState;
  public UnitOfMeasurementEnum = UnitOfMeasurementEnum;
  public chartTypes: Array<ISelectItem<SeriesTypes>> = [
    {
      text: this.$t('global.chartTypes.Column').toString(),
      value: SeriesTypes.Column,
    },
    {
      text: this.$t('global.chartTypes.Line').toString(),
      value: SeriesTypes.Line,
    },
    {
      text: this.$t('global.chartTypes.Spline').toString(),
      value: SeriesTypes.Spline,
    },
    {
      text: this.$t('global.chartTypes.Area').toString(),
      value: SeriesTypes.Area,
    },
    {
      text: this.$t('global.chartTypes.AreaSpline').toString(),
      value: SeriesTypes.AreaSpline,
    },
  ];

  public get remarks(): string {
    return this.reportVariable.state ? this.$t(`report.variable.${this.reportVariable.state}`).toString() : '';
  }

  public get currentMeasurementSystem(): string {
    return reports.currentMeasurementSystem;
  }

  public get mergedRemarks(): string {
    let remarks = '';

    if (this.reportVariable.mergedDetails) {
      const pres = (this.reportVariable.mergedDetails.preDisplayNames.join(', ') as string).replaceLastCommaWith(
        ' and'
      );
      const posts = (this.reportVariable.mergedDetails.postDisplayNames.join(', ') as string).replaceLastCommaWith(
        ' and'
      );

      switch (this.reportVariable.mergedDetails.mergedState) {
        case ReportVariableMergedState.Merged:
          remarks = this.$t('report.variable.Merged', { variables: pres, mergedVariable: posts }).toString();
          break;

        case ReportVariableMergedState.Unmerged:
          remarks = this.$t('report.variable.Unmerged', { variable: pres, unmergedVariables: posts }).toString();
          break;
      }
    }

    return remarks;
  }

  get selectedAxis(): ReportAxis {
    return this.reportVariable.axis;
  }

  set selectedAxis(reportAxis: ReportAxis) {
    const variable: IReportVariableViewModel = new ReportVariableViewModel(this.reportVariable);
    variable.axis = reportAxis;
    reports.updateActiveReportVariable(variable);
    // this.refresh();
  }

  get nodeColor(): string {
    return SeriesColors.getColor(this.variableIndex);
  }

  // Event Methods
  public updateChartType(chartType: SeriesTypes): void {
    const variable: IReportVariableViewModel = new ReportVariableViewModel(this.reportVariable);
    variable.chartType = chartType;
    reports.updateActiveReportVariable(variable);
    this.refresh();
  }

  public async onDeleteInvalidReportVariable(reportVariable: IReportVariableViewModel): Promise<void> {
    reports.removeReportVariableByKey(reportVariable.key);
    await reports.requestVariablesData({ key: this.currentMeasurementSystem, changed: false } );
  }

  public updateUnitOfMeasurement(unitOfMeasurement: UnitOfMeasurementEnum): void {
    this.updateUOM(unitOfMeasurement);
  }

  // Helper methods...
  public unitOfMeasurements(measurementType: MeasurementTypesEnum): Array<ISelectItem<UnitOfMeasurementEnum>> {
    const result: Array<ISelectItem<UnitOfMeasurementEnum>> = [];
    const uoms: UnitOfMeasurementEnum[] = getUoMBasedOnMeasurementType(measurementType);

    if (uoms.includes(this.reportVariable.unitOfMeasurement)) {
      uoms.forEach((uom) => {
        result.push({
          text: this.$t(`global.uom.${uom}`).toString(),
          value: uom
        });
      });
    } else {
      result.push({
        text: this.$t(`global.uom.${this.reportVariable.unitOfMeasurement}`).toString(),
        value: this.reportVariable.unitOfMeasurement
      });
    }

    return result;
  }

  @Emit ('updateUOM')
  public updateUOM(unitOfMeasurement: UnitOfMeasurementEnum) {
    const reportVariable: IReportVariableUomModel = { reportVariable: this.reportVariable, toUoM: unitOfMeasurement };
    return reportVariable;
  }
  // Emitters
  @Emit('refresh')
  public refresh() {}
}
