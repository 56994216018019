













































































































































// Libraries
import { Component, Model, Emit, Prop, Vue, Ref } from 'vue-property-decorator';
// @ts-ignore
import { required } from 'vee-validate/dist/rules.umd.js';
import { ValidationProvider, extend } from 'vee-validate';
import { elementId } from '@/shared/component-utils';
// View Models
import { IReportViewModel } from '@/view-models/reports';
import { ISelectItem } from '@/components/types/select-box';
// Components
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
// Stores
import reports from '@/store/reports';

@Component({
  name: 'save-report-modal',
  components: { SelectDropdown, ValidationProvider }
})
export default class SaveReportModal extends Vue {
  // VUE.JS Props
  @Model('input', { required: true })
  public value: boolean;
  @Prop({ required: false, default: true })
  public showOverwriteSection: boolean;
  @Prop({ required: false, default: true })
  public showNewSection: boolean;

  // Refs
  @Ref('saveNew')
  public saveNewModal!: Vue;

  // VUEX
  // Properties
  public duplicateNameExists: boolean = false;
  public saveName: string = '';
  public overwriteKey: string = null;
  public isRequestOut: boolean = false;
  public elementId: (
    entityType: string,
    propertyOrActionOrInputName: string,
    entityKey?: string
  ) => string = elementId;

  // Fields
  // Getters
  public get existingOptions(): ISelectItem<string>[] {
    return reports.reportsList.map((report) => ({
      text: report.name,
      value: report.key
    }));
  }
  public get entityTypeName(): string {
    return this.$t('report.report').toString().toLowerCase();
  }
  public get isSaveDisabled(): boolean {
    return this.saveName === '' || this.isRequestOut;
  }
  public get isOverwriteDisabled(): boolean {
    return this.overwriteKey == null || this.isRequestOut;
  }
  public get executedReport(): IReportViewModel {
    return reports.executedReport;
  }
  public get validationRulesForSaveNew(): {required: boolean} {
    return {
      required:  !this.$route.params.key
    };
  }
  // Lifecycle Handlers
  // beforeCreate(): void {}
  public created(): void {
    const vueInstance = this;
    extend('required', {
      ...required,
      message: 'The field is required'
    });

    extend('unique-report-name', {
      validate(unused) {
        return vueInstance.duplicateNameExists ? false : true;
      },
      message: 'Existing name do you want to overwrite'
    });
  }
  // beforeMount(): void {}
  // private mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  private checkName(): Promise<IReportViewModel> {
    return reports.getReportByName(this.saveName);
  }
  private pushRoute(id: string): void {
    if (this.$route.path !== `/view/${id}`) {
      this.$router.push(`/view/${id}`);
    }
  }

  // Helper Methods
  // Event Methods
  public getValidationState(data: {dirty: boolean, validated: boolean, valid: boolean}): boolean {
    const result = data.dirty || data.validated ? data.valid : null;
    return !this.duplicateNameExists && result;
  }
  public modalShown(): void {
    if (reports.activeReport.key) {
      this.overwriteKey = reports.activeReport.key;
    }
    const input: HTMLInputElement = this.saveNewModal.$el as HTMLInputElement;
    input.focus();
  }
  public modalHide(): void {
    this.shown(false);
    this.saveName = '';
    this.overwriteKey = null;
    this.duplicateNameExists = false;
  }
  public updateReportName(): void {
    reports.setReportName(
      this.saveName ? this.saveName : this.executedReport.name
    );
  }
  public restoreReportName(): void {
    reports.setReportName(this.executedReport.name);
  }
  public async saveReport(): Promise<void> {
    this.isRequestOut = true;

    try {
      const existingReport = await this.checkName();
      if (existingReport != null) {
        this.updateOverwriteKey(existingReport.key);
        this.duplicateNameExists = true;
      } else {
        try {
          const report = await reports.saveNewReport(this.saveName);
          this.pushRoute(report.key);
          reports.addReportToList(report);
        } finally {
          this.shown(false);
        }
      }
    } finally {
      this.isRequestOut = false;
      if (!this.duplicateNameExists) {
        this.shown(false);
      }
    }
  }
  public updateOverwriteKey(id: string): void {
    this.overwriteKey = id;
  }
  public async overwriteEntity(): Promise<void> {
    this.isRequestOut = true;
    try {
      await reports.overwriteExistingReport(this.overwriteKey);

      this.shown(false);
      this.pushRoute(this.overwriteKey);
    } finally {
      this.isRequestOut = false;
    }
  }
  // Watchers
  // Emitters
  @Emit('input')
  public shown(unused: boolean): void {}
}
