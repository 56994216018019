import { ReportRangeType, SkipDirectionsEnum } from '@/enums/report-enums';
import { DashboardTimeRangesEnum } from '@/enums/widget-enums';
import EftTime from '@/shared/eft-time';
import {
  DateRange,
  IDateRange,
  IDateRangeObjects
} from '@/view-models/report-time-model';
import moment from 'moment';

export class ReportTimeHelper {
  public static readonly NEXT: boolean = true;
  public static readonly PREVIOUS: boolean = false;
  public static direction: boolean;

  public static seriesTimestampToDate(timestamp: number) {
    // Create date that is off by the timezone offset.
    const prelimDate = new Date(timestamp);
    // We need the offset of the preliminary date (outside of DST most likely).
    const offset = prelimDate.getTimezoneOffset() * EftTime.minute * 1000;
    return new Date(timestamp + offset);
  }

  public static calculateDateRangeFromRangeType(rangeType: ReportRangeType): IDateRange {
    let toDate: Date = new Date();
    let fromDate: Date = new Date();
    switch (rangeType) {
      case ReportRangeType.Today:
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        toDate.setDate(fromDate.getDate() + 1);
        toDate.setHours(0);
        toDate.setMinutes(0);
        toDate.setSeconds(0);
        break;
      case ReportRangeType.Yesterday:
        fromDate.setDate(fromDate.getDate() - 1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        toDate.setDate(toDate.getDate());
        toDate.setHours(0);
        toDate.setMinutes(0);
        toDate.setSeconds(0);
        break;
      case ReportRangeType.Last24Hours:
        fromDate = new Date(fromDate.getTime() - EftTime.day * 1000);
        break;
      case ReportRangeType.Last7Days:
        fromDate.setDate(fromDate.getDate() + 1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 7);
        break;
      case ReportRangeType.Last30Days:
        fromDate.setDate(fromDate.getDate() + 1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 30);
        break;
      case ReportRangeType.Last90Days:
        fromDate.setDate(fromDate.getDate() + 1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 90);
        break;
      case ReportRangeType.Last180Days:
        fromDate.setDate(fromDate.getDate() + 1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        fromDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 180);
        break;
      case ReportRangeType.MonthToDate:
        fromDate.setDate(1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        break;
      case ReportRangeType.YearToDate:
        fromDate.setMonth(0);
        fromDate.setDate(1);
        fromDate.setHours(0);
        fromDate.setMinutes(0);
        fromDate.setSeconds(0);
        break;
      // case ReportRangeType.SpecificYear:
      //   fromDate.setMonth(0);
      //   fromDate.setDate(1);
      //   fromDate.setHours(0);
      //   fromDate.setMinutes(0);
      //   fromDate.setSeconds(0);
      //   toDate.setMonth(12);
      //   toDate.setDate(31);
      //   toDate.setHours(0);
      //   toDate.setMinutes(0);
      //   toDate.setSeconds(0);
      //   break;
      case ReportRangeType.Custom:
        if (this.direction === this.NEXT) {
          const diff: number = toDate.getTime() - fromDate.getTime();
          fromDate = new Date(toDate);
          toDate = new Date(fromDate.getTime() + diff);
        } else {
          const diff: number = toDate.getTime() - fromDate.getTime();
          toDate = new Date(fromDate);
          fromDate = new Date(fromDate.getTime() - diff);
        }
    }
    const range: IDateRange = new DateRange({
      toDate: toDate.toISOString(),
      fromDate: fromDate.toISOString()
    });
    return range;
  }

  public static rangeTypeToDateRange(value: ReportRangeType) {
    const range = ReportTimeHelper.calculateDateRangeFromRangeType(value);
    return DateRange.prepareRangeForServer(range);
  }

  public static calculateWidgetDateRangeFromRangeType(rangeType: DashboardTimeRangesEnum): IDateRange {
    let toDate: Date = new Date();
    let fromDate: Date = new Date();

    const padRangeTypes: Array<DashboardTimeRangesEnum> = [
      DashboardTimeRangesEnum.OneDay,
      DashboardTimeRangesEnum.OneMonth,
      DashboardTimeRangesEnum.Custom
    ];

    if (padRangeTypes.includes(rangeType)) {
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(0);
      fromDate.setMilliseconds(0);

      toDate.setHours(23);
      toDate.setMinutes(59);
      toDate.setSeconds(59);
      toDate.setMilliseconds(0);
    }

    switch (rangeType) {
      case DashboardTimeRangesEnum.OneHour:
        toDate.setHours(fromDate.getHours() - 1);
        break;
      case DashboardTimeRangesEnum.EightHours:
        toDate.setHours(fromDate.getHours() - 8);
        break;
      case DashboardTimeRangesEnum.OneWeek:
        toDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 7);
        break;
      case DashboardTimeRangesEnum.OneMonth:
        toDate = new Date(fromDate.getTime() - EftTime.day * 1000 * 30);
        break;
      case DashboardTimeRangesEnum.OneDay:
        // toDate = new Date();
        // fromDate = new Date();
        toDate.setDate(fromDate.getDate() - 1);
        break;
      case DashboardTimeRangesEnum.Custom:
        if (this.direction === this.NEXT) {
          const diff: number = toDate.getTime() - fromDate.getTime();
          fromDate = new Date(toDate);
          toDate = new Date(fromDate.getTime() + diff);
        } else {
          const diff: number = toDate.getTime() - fromDate.getTime();
          toDate = new Date(fromDate);
          fromDate = new Date(fromDate.getTime() - diff);
        }
    }
    const range: IDateRange = new DateRange({
      toDate: toDate.toISOString(),
      fromDate: fromDate.toISOString()
    });
    return range;
  }

  public static widgetRangeTypeToDateRange(value: DashboardTimeRangesEnum) {
    const range = ReportTimeHelper.calculateWidgetDateRangeFromRangeType(value);
    return DateRange.prepareRangeForServer(range);
  }
}

export function skipDateRange(range: IDateRange, direction: SkipDirectionsEnum, type: ReportRangeType): IDateRange {
  let fromDate = moment(range.fromDate);
  let toDate = moment(range.toDate);

  const toDateEnd = moment(range.toDate).endOf('day').clone();
  const duration = moment.duration(toDateEnd.diff(fromDate));

  if (direction === SkipDirectionsEnum.Backwards) {
    if (type === ReportRangeType.SpecificYear) {
      fromDate.subtract(1, 'years');
      toDate.subtract(1, 'years');
    } else {
      fromDate.subtract(duration);
      toDate.subtract(duration);
    }
  } else {
    if (type === ReportRangeType.SpecificYear) {
      fromDate.add(1, 'years');
      toDate.add(1, 'years');
    } else {
      fromDate.add(duration);
      toDate.add(duration);
    }
  }

  const now = moment();
  if (toDate.isAfter(now)) {
    toDate = now.clone();
    fromDate = now.clone().subtract(duration);
  }

  return {
    fromDate: fromDate.toDate().toISOString(),
    toDate: toDate.toDate().toISOString()
  };
}

export function buildDashboardDateRange(value: ReportRangeType): IDateRangeObjects {
  const toDate = new Date();
  let fromDate = new Date();

  if (value === ReportRangeType.Today) {
    fromDate = moment().startOf('day').toDate();
  } else if (value === ReportRangeType.MonthToDate) {
    fromDate = moment().startOf('month').toDate();
  } else if (value === ReportRangeType.YearToDate) {
    fromDate = moment().startOf('year').toDate();
  } else {
    const duration = timeRangeToDuration(value);
    fromDate = moment(toDate)
      .subtract(duration)
      .toDate();
  }

  return { fromDate, toDate };
}

function timeRangeToDuration(value: ReportRangeType): moment.DurationInputObject {
  switch (value) {
    case ReportRangeType.Yesterday:
      return { day: 1 };
    case ReportRangeType.Last24Hours:
      return { hours: 24 };
    case ReportRangeType.Last7Days:
      return { days: 7 };
    case ReportRangeType.Last30Days:
      return { days: 30 };
    case ReportRangeType.Last90Days:
      return { days: 90 };
    case ReportRangeType.Last180Days:
      return { days: 80 };
    default:
      return { day: 1 };
  }
}
