



// Libraries
import { Component, Vue } from 'vue-property-decorator';
// View Models
// Components
import ReportsTable from '@/components/ReportsTable.vue';
// Stores
import reports from '@/store/reports';

@Component({
  name: 'home-page',
  components: {
    ReportsTable
  }
})
export default class HomePage extends Vue {
  // VUE.JS Props
  // Refs
  // VUEX
  // Properties
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  public async beforeMount(): Promise<void> {
    reports.resetReport();
  }
  // mounted(): void
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  // Watchers
  // Emitters
}
