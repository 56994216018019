import { authGuard } from '@/shared/auth';
import VueRouter from 'vue-router';
import HomePage from '@/pages/Home.vue';
import Settings from '@/pages/Settings.vue';
import ReportViewPage from '@/pages/view/_key.vue';
import PageNotFound from '@/pages/PageNotFound.vue';
import Vue from 'vue';
import { BaseProvider } from '@/shared/providers/base-provider';
import { appRouteBase } from '@/shared/from-parent';
import appStore from '@/store/app';

Vue.use(VueRouter);

export enum PagesEnum {
  home = 'HomePage',
  viewReport = 'ReportView',
  addReport = 'ReportCreate',
  editReport = 'ReportEdit',
  notFound = 'NotFound',
  unknown = 'Unknown'
}

const routes = [
  { path: '/', component: HomePage, name: PagesEnum.home },
  { path: '/view/:key?', component: ReportViewPage, name: PagesEnum.viewReport },
  { path: '/settings', component: Settings, name: PagesEnum.addReport },
  { path: '/settings/:key', component: Settings, name: PagesEnum.editReport },
  { path: '/notFound', component: PageNotFound, name: PagesEnum.notFound },
  { path: '*', component: PageNotFound, name: PagesEnum.unknown },
];

class RouterProvider extends BaseProvider<VueRouter> {
  constructor() {
    super('Router');
    this.instance = new VueRouter({
      mode: 'history',
      base: appRouteBase(),
      routes,
    });
  }

  public init(): void {
    this.instance.beforeEach(authGuard);
    this.instance.afterEach(() => {
      appStore.setIsLoading(false);
    });
  }
}

export default new RouterProvider();
