





import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

/**
 * THIS IS A COPY FROM COMMONLIBRARY
 * if you make changes here, make sure they are also done in the COMMONLIBRARY
 *
 * @TODO Not depend on copy pasting dsm components, but just reference them.
 */

@Component({
  name: 'icon-button',
})
export default class IconButton extends Vue {
  @Prop({ default: false })
  public readonly disabled!: boolean;
  @Prop({ required: true })
  public readonly iconClass!: string;
  @Prop()
  public readonly text?: string;

  @Emit('click')
  public onClick(eventEmit: MouseEvent) {}
}
