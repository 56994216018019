



















// Libraries
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
// View Models
// Components

@Component({
  name: 'confirmation-modal'
})
export default class ConfirmationModal extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public show!: boolean;
  @Prop({ required: true })
  public header: string;
  @Prop({ required: true })
  public message: string;

  // Refs
  // VUEX
  // Properties
  public get showModal(): boolean {
    return this.show;
  }
  public set showModal(show: boolean) {
    this.submitEmit(show);
  }
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public onCancel() {
    this.showModal = false;
  }
  // Watchers
  // Emitters
  @Emit('submit')
  public submitEmit(confirmedEmit: boolean) {}
}
