import { ReportVariableState } from '@/enums/report-variable-state';
import { IReportVariableMergedDetailsViewModel, IReportVariableViewModel } from '@/view-models/reports';
import { SeriesOptionsType } from 'highcharts';

export enum ReportCallError {
  NoVariableAccess = 'UserNotAuthorizedForVariable'
}

export enum ReportField {
  MaximumCount = 32,
  DefaultSampleSize = 10000,
  ReducedSampleSize = 5000,
  MinimumSampleSize = 2000,
}

export type SeriesData = Array<number | Array<number | string | null> | object | null>;
export type ReportSeriesOptionsType = SeriesOptionsType & {
  name: string;
  data?: SeriesData;
  color?: string;
  borderColor?: string;
  state: ReportVariableState;
  mergedDetails?: IReportVariableMergedDetailsViewModel;
  assetKey?: string;
  reportVariable?: IReportVariableViewModel;
};

export default class ReportHelper {
  public static isVariableAccessError(response: any): boolean {
    return response.data && response.data.error === ReportCallError.NoVariableAccess;
  }

  public static maxYValue(seriesList: Array<ReportSeriesOptionsType>, yAxis: number): number {
    return ReportHelper.mathYValue(seriesList, yAxis, Math.max);
  }

  public static minYValue(seriesList: Array<ReportSeriesOptionsType>, yAxis: number): number {
    return ReportHelper.mathYValue(seriesList, yAxis, Math.min);
  }
  public static mathYValue(
    seriesList: Array<ReportSeriesOptionsType>,
    yAxis: number,
    mathFun: (...args: any[]) => number,
  ): number {
    const seriesListFiltered = seriesList.filter((s) => s.yAxis === yAxis);
    const seriesListFlattened = seriesListFiltered.flatMap((s: any) => s.data);
    const dataList = seriesListFlattened.filter((d: any) => !!d);

    return mathFun.apply(Math, dataList.map((tuple: any) => tuple[1]));
  }
  public static isExceedVariableLimit(length: number, count: number): boolean {
    return ((count + length) > ReportField.MaximumCount) ? true : false;
  }

  public static getVariableKey(variable: IReportVariableViewModel) {
    return `${variable.assetKey}#${variable.nodeKey}#${variable.dataRefs[0]}`;
  }
}
