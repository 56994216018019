import axios, { AxiosInstance } from 'axios';
import config from '@/config';
import { BaseProvider } from '@/shared/providers/base-provider';
import { authInterceptor } from '@/shared/auth';

class HttpProvider extends BaseProvider<AxiosInstance> {
  constructor(private getBaseUrl: (() => string)) {
    super('Http Client');
  }

  public init(): void {
    this.instance = axios.create({
      baseURL: this.getBaseUrl(),
      timeout: 20000
    });
    this.instance.interceptors.request.use(authInterceptor);
  }
}

const http: HttpProvider = new HttpProvider(() => config.instance.baseApiUrl);
const userManagementHttp: HttpProvider = new HttpProvider(() => config.instance.userManagementApiUrl);
export { http, userManagementHttp };

export function initializeHttpClients() {
  http.init();
  userManagementHttp.init();
}
