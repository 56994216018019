import ControllerBaseService from '@/services/common/controller-base-service';
import { IHierarchyTreeViewModel } from '@/view-models/report-variables-tree/report-variable-node';
import { AxiosInstance } from 'axios';
import { http } from './common/http';

export class AssetHierarchyService extends ControllerBaseService {

  constructor(axios: AxiosInstance) {
    super('report-asset-hierarchy', axios);
  }

  public static factory(): AssetHierarchyService {
    return new AssetHierarchyService(http.instance);
  }

  public getAssetHierarchyTrees(assetKey: string): Promise<IHierarchyTreeViewModel[]> {
    return this.httpGet<IHierarchyTreeViewModel[]>(assetKey, { includeAsset: true });
  }
}
