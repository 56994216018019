export enum ReportVariableState {
  Ok = 'Ok',
  NoData = 'NoData',
  NonNumericData = 'NonNumericData',
}

export enum ReportVariableMergedState {
  None = 'None',
  Merged = 'Merged',
  Unmerged = 'Unmerged',
}
