import { ReportAxis, ReportRangeType, SeriesTypes } from '@/enums/report-enums';
import { ReportVariableMergedState, ReportVariableState } from '@/enums/report-variable-state';
import { UnitOfMeasurementEnum } from '@/enums/unit-of-measurement';
import { MeasurementTypesEnum } from '@/enums/variables';
import { newGuid, replaceAll } from '@/shared/string-utils';
import reportVariablesTree from '@/store/report-variables-tree';
import { DateRange, IDateRange } from './report-time-model';

export interface ReportVariableData {
  timestamp: number;
  data: number;
}

// TODO: this should be renamed IReportViewModel to follow the convention
// of naming the models coming from the API
export interface IReport {
  key: string;
  name: string;
  orgKey: string;
  createdAt: string;
  createdBy: string;
  modifiedAt?: string;
  modifiedBy?: string;
  userKey: string;
  leftAxisUoM: string;
  rightAxisUoM: string;
  rangeType: ReportRangeType;
  fromDate?: string;
  toDate?: string;
  specificYear?: number;
  reportVariables: IReportVariableViewModel[];

  // Client only
  reportVariableGroups: IGroupedReportVariables[];
  isFavorite?: boolean;
  checksum?: string;
  showGridLines: boolean;
  sampleSize?: number;
}

// TODO: this should use a different name, maybe IReport or maybe it could be merged with above.
// If you change the name also change the name of the implementing class
export interface IReportViewModel extends IReport {
  dateRange: IDateRange;
  prepareForNewSave(reportName: string): void;
  determineRange(existingRange: IDateRange): void;
  isDirty(): boolean;
  resetChecksum(): void;
}

export interface IReportDataViewModel {
  key: string;
  data: Array<ReportVariableData>;
  state: ReportVariableState;
  mergedDetails?: IReportVariableMergedDetailsViewModel;

  // Client-side
  displayValue: string;
  name: string;
}

export class ReportViewModel implements IReportViewModel {
  public key: string = '';
  public name: string = '';
  public orgKey: string = '';
  public createdAt: string = undefined;
  public createdBy: string = '';
  public modifiedAt?: string;
  public modifiedBy?: string;
  public userKey: string;
  public leftAxisUoM: string;
  public rightAxisUoM: string;
  public rangeType: ReportRangeType = ReportRangeType.Today;
  public fromDate?: string;
  public toDate?: string;
  public specificYear?: number = new Date().getFullYear() - 1;
  public reportVariables: IReportVariableViewModel[] = [];
  public reportVariableGroups: IGroupedReportVariables[] = [];
  public checksum?: string;
  public showGridLines: boolean = false;
  public sampleSize: number;

  constructor(report?: IReport) {
    if (report != null) {
      Object.assign(this, report);
      this.reportVariables = (report.reportVariables || []).map(
        (variable: IReportVariableViewModel) => new ReportVariableViewModel(variable)
      );
      this.reportVariableGroups = this.buildReportVariableGroups(this.reportVariables);
      this.resetChecksum();
    }
  }

  public get dateRange(): IDateRange {
    return new DateRange({ fromDate: this.fromDate, toDate: this.toDate });
  }
  public set dateRange(range: IDateRange) {
    this.fromDate = range.fromDate;
    this.toDate = range.toDate;
  }

  private buildReportVariableGroups(reportVariables: IReportVariableViewModel[]): IGroupedReportVariables[] {
    const groups: IGroupedReportVariables[] = this.reportVariableGroups;
    reportVariables.forEach((variable) => {
      const matchedReportVariableGroup = this.reportVariableGroups.find(
        (group: IGroupedReportVariables) => group.key === variable.nodeKey
      );

      if (matchedReportVariableGroup) {
        const matchedReportVariable = matchedReportVariableGroup.reportVariables.find(
          (reportVariable) => reportVariable.key === variable.key
        );
        if (!matchedReportVariable) {
          matchedReportVariableGroup.reportVariables.push(variable);
        }
      } else {
        let groupName = 'Unknown Group';
        if (variable.topDownHierarchyPathName) {
          groupName = variable.topDownHierarchyPathName;
        } else {
          const variableNode = reportVariablesTree.flattenedVariableNodes.find(
            (node) =>
              node.isLeaf &&
              node.assetKey === variable.assetKey &&
              node.dataNodeKey === variable.nodeKey &&
              node.dataRefs?.equalsTo(variable.dataRefs, true)
          );

          if (variableNode) {
            groupName = variableNode.topDownHierarchyNames
              .slice(0, variableNode.topDownHierarchyNames.length - 1)
              .join('/ ');
          }
        }

        const newReportVariableGroup: IGroupedReportVariables = {
          key: variable.nodeKey,
          name: groupName,
          reportVariables: [variable],
          isOpen: true,
        };

        groups.push(newReportVariableGroup);
      }
    });

    return groups;
  }

  public static rangeTypeToDateRange(rangeType: ReportRangeType, year: number, existingRange: IDateRange): IDateRange {
    let from: Date = new Date();
    let to: Date = new Date();
    if (rangeType === ReportRangeType.Custom) {
      // The logic for non-Custom assumes From and To are today to begin with.
      if (existingRange != null && existingRange.fromDate && existingRange.toDate) {
        from = new Date(existingRange.fromDate);
        to = new Date(existingRange.toDate);
      }
    } else {
      // const padRangeTypes: Array<ReportRangeType> = [
      //   ReportRangeType.Today,
      //   ReportRangeType.Yesterday,
      //   ReportRangeType.SpecificYear,
      //   ReportRangeType.Custom
      // ];
      from.setHours(0);
      from.setMinutes(0);
      from.setSeconds(0);
      from.setMilliseconds(0);
      // if (padRangeTypes.includes(rangeType)) {
      //   to.setHours(23);
      //   to.setMinutes(59);
      //   to.setSeconds(59);
      //   to.setMilliseconds(0);
      // }
    }
    switch (rangeType) {
      case ReportRangeType.Custom:
        break;
      case ReportRangeType.Today:
        break;
      case ReportRangeType.Yesterday:
        to.setDate(to.getDate() - 1);
        to.setHours(23);
        to.setMinutes(59);
        to.setSeconds(59);
        from.setDate(from.getDate() - 1);
        break;
      case ReportRangeType.Last24Hours:
        to = new Date();
        from = new Date();
        from.setDate(from.getDate() - 1);
        break;
      case ReportRangeType.Last7Days:
        from.setDate(from.getDate() - 6);
        break;
      case ReportRangeType.Last30Days:
        from.setDate(from.getDate() - 29);
        break;
      case ReportRangeType.Last90Days:
        from.setDate(from.getDate() - 89);
        break;
      case ReportRangeType.Last180Days:
        from.setDate(from.getDate() - 179);
        break;
      case ReportRangeType.MonthToDate:
        from.setDate(1);
        break;
      case ReportRangeType.YearToDate:
        from.setMonth(0, 1);
        break;
      case ReportRangeType.SpecificYear:
        from.setFullYear(year);
        from.setMonth(0, 1);
        to.setFullYear(year);
        to.setMonth(11, 31);
        break;
    }
    return new DateRange({
      fromDate: from.toISOString(),
      toDate: to.toISOString(),
    });
  }

  public prepareForNewSave(reportName: string): void {
    this.key = '';
    this.name = reportName;
    this.createdAt = undefined;
  }
  public isDirty(): boolean {
    return this.checksum !== this.getChecksum();
  }

  public resetChecksum(): void {
    this.checksum = this.getChecksum();
  }

  private getChecksum(): string {
    const copy: IReportViewModel = Object.assign({}, this);
    copy.checksum = '';
    const newCheckSum = JSON.stringify(copy);
    return newCheckSum;
  }

  public determineRange(existingRange: IDateRange): void {
    const newDateRange = ReportViewModel.rangeTypeToDateRange(this.rangeType, this.specificYear, existingRange);
    const prevIsDirty = this.isDirty();
    try {
      this.fromDate = newDateRange.fromDate;
      this.toDate = newDateRange.toDate;
    } finally {
      if (!prevIsDirty) {
        // Change to report range type should not make the report dirty if it wasn't.
        this.resetChecksum();
      }
    }
  }
}

export interface IReportSnapshot {
  report: IReportViewModel;
  executedReport: IReportViewModel;
  reportData: IReportDataViewModel[];
}

export class ReportSnapshot {
  public report: IReportViewModel = new ReportViewModel();
  public executedReport: IReportViewModel = new ReportViewModel();
  public reportData: IReportDataViewModel[] = [];

  constructor(snapshot?: IReportSnapshot) {
    if (snapshot != null) {
      this.report = new ReportViewModel(snapshot.report);
      this.executedReport = new ReportViewModel(snapshot.executedReport);
      this.reportData = snapshot.reportData.slice(0);
    }
  }
}

export interface IReportVariableUomModel {
  reportVariable: IReportVariableViewModel;
  toUoM: UnitOfMeasurementEnum;
}

export interface IReportVariableViewModel {
  key: string;
  assetKey: string;
  nodeKey: string;
  dataRefs: string[];
  displayName: string;
  sortOrder: number;
  axis: ReportAxis;
  chartType: SeriesTypes;
  tempusTableKey: string;
  unitOfMeasurement: UnitOfMeasurementEnum;
  mergedDetails?: IReportVariableMergedDetailsViewModel;

  // client side
  measurementType: MeasurementTypesEnum;
  state?: ReportVariableState;
  topDownHierarchyPathName?: string;
}

export interface IReportVariableMergedDetailsViewModel {
  mergedState: ReportVariableMergedState;
  preDisplayNames: string[];
  postDisplayNames: string[];
}

export interface IGroupedReportVariables {
  key: string;
  name: string;
  reportVariables: IReportVariableViewModel[];
  isOpen: boolean;
}

export class ReportVariableViewModel implements IReportVariableViewModel {
  public key: string = newGuid();
  public assetKey: string = '';
  public nodeKey: string = '';
  public dataRefs: string[] = [];
  public displayName: string = '';
  public sortOrder: number = 0;
  public axis: ReportAxis = ReportAxis.Left;
  public chartType: SeriesTypes = SeriesTypes.Line;
  public isOpen: boolean = false;
  public tempusTableKey: string;
  public unitOfMeasurement: UnitOfMeasurementEnum = UnitOfMeasurementEnum.Default;
  public state: ReportVariableState = ReportVariableState.Ok;
  public topDownHierarchyPathName?: string;
  public measurementType: MeasurementTypesEnum = MeasurementTypesEnum.Unknown;
  public mergedDetails?: IReportVariableMergedDetailsViewModel;

  constructor(variable?: IReportVariableViewModel) {
    if (variable != null) {
      Object.assign(this, variable);
    }
  }
}

export type UnixEpochTime = number; // milliseconds
