import moment from 'moment';
import { UnixEpochTime } from '@/view-models/reports';
import { Nil } from '@/shared/types';

export function toRelativeTime(dateTime: DateTimeInput): string {
  if (dateTime == null) {
    return '';
  }
  const m = moment(dateTime);
  return m.fromNow();
}

// EDGE does not want the T to be removed from date string.
// asIs is an optional parameter that determines if T is removed or not
export function asDate(date: any, asIs: boolean = false): Date {
  if (date == null) {
    return new Date();
  } else if (date instanceof Date) {
    return isNaN(date.valueOf()) ? new Date() : date as Date;
  } else if (typeof date === 'number') {
    return new Date(date);
  } else if (moment.isMoment(date)) {
    return (date as moment.Moment).toDate();
  } else if (typeof date === 'string') {
    if (!asIs) {
      date = date.replace('T', ' ');
    }
    return new Date(Date.parse(date));
  } else if (typeof date === 'boolean') {
    if (!date) {
      return new Date();
    }
  } else {
    throw new TypeError('Bad date type: ' + date);
  }

  return new Date();
}
export function getDaysInMonth(date: Date): number {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
}
export function asUnixTimestamp(date: any): UnixEpochTime {
  const sureDate = asDate(date);
  return sureDate == null ? 0 : sureDate.valueOf();
}
export type DateTimeInput = Date | string | number;

export function asDateTime(input: Nil<DateTimeInput>): Nil<Date> {
  if (input == null) {
    return null;
  }
  if (typeof input === 'string') {
    return new Date(Date.parse(input));
  }
  if (typeof input === 'number') {
    return new Date(input);
  }

  throw new Error(`ERROR: unknown date type: ${input}`);
}

export interface IDateTransformOptions {
  years?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
}

const transformMultiplierTable: Required<IDateTransformOptions> = {
  years: 52 * 7 * 24 * 60 * 60 * 1000,
  weeks: 7 * 24 * 60 * 60 * 1000,
  days: 24 * 60 * 60 * 1000,
  hours: 60 * 60 * 1000,
  minutes: 60 * 1000,
  seconds: 1000,
  milliseconds: 1
};

export function transformDate(input: DateTimeInput, options: IDateTransformOptions, action: 'add' | 'subtract'): Date {
  let milliseconds = asDateTime(input)?.valueOf() ?? 0;
  milliseconds = Object.entries(options).reduce((acc, entry) => {
    const [type, multiplier] = entry as [keyof IDateTransformOptions, number];
    const m = multiplier * transformMultiplierTable[type];
    return action === 'add' ? acc + m : acc - m;
  }, milliseconds);

  return asDateTime(milliseconds) as Date;
}

export function addDateTime(input: DateTimeInput, options: IDateTransformOptions) {
  return transformDate(input, options, 'add');
}

export function subtractDateTime(input: DateTimeInput, options: IDateTransformOptions) {
  return transformDate(input, options, 'subtract');
}

export function displayDate(input: DateTimeInput, locale?: string) {
  const date = asDateTime(input) as Date;
  const formatter = new Intl.DateTimeFormat(locale , {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  });
  return formatter.format(date);
}

export function displayDateTime(input: DateTimeInput, locale?: string) {
  const date = asDateTime(input) as Date;
  const formatter = new Intl.DateTimeFormat(locale , {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour12: true,
    hour: 'numeric',
    minute: '2-digit'
  });
  return formatter.format(date);
}
export function getDateTime(date: string, format: string = 'MM/DD/YYYY hh:mm:ss A') {
  const formatter: moment.Moment = moment.utc(date).local();
  return formatter.format(format);
}

export function startOfDay(input?: DateTimeInput): Date {
  const date = asDateTime(input ?? Date.now());
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    1, 0, 0, 0
  );
}

export function endOfDay(input?: DateTimeInput): Date {
  const date = asDateTime(input ?? Date.now());
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    0, 0, 0, 0
  );
}

export function dateInBetween(start: DateTimeInput, value: DateTimeInput, end: DateTimeInput): boolean {
  const startDate = asDateTime(start);
  const valueDate = asDateTime(value);
  const endDate = asDateTime(end);

  return startDate > valueDate && valueDate < endDate;
}

export function debounce(name: string, delay: number, action: Function): void {
  //@ts-ignore
  window.clearTimeout(window[`debounce${name}`]);

  // @ts-ignore
  window[`debounce${name}`] = window.setTimeout(action, delay);
}
