const defaultDecimal = {
  style: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};

const defaultDataVariableDecimal = {
  style: 'decimal',
  minimumFractionDigits: 0,
  maximumFractionDigits: 20
};

export enum NumberFormatKey {
  Decimal = 'decimal',
  Currency = 'currency',
  VariableDecimal = 'variable-decimal'
}

export default {
  'en-US': {
    [NumberFormatKey.Decimal]: defaultDecimal,
    [NumberFormatKey.VariableDecimal]: defaultDataVariableDecimal,
    [NumberFormatKey.Currency]: {
      style: 'currency',
      currency: 'USD'
    }
  },
  'fr-FR': {
    [NumberFormatKey.Decimal]: defaultDecimal,
    [NumberFormatKey.VariableDecimal]: defaultDataVariableDecimal,
    [NumberFormatKey.Currency]: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  },
  'en-GB': {
    [NumberFormatKey.Decimal]: defaultDecimal,
    [NumberFormatKey.VariableDecimal]: defaultDataVariableDecimal,
    [NumberFormatKey.Currency]: {
      style: 'currency',
      currency: 'GBP',
      currencyDisplay: 'symbol'
    }
  },
  'en-IE': {
    [NumberFormatKey.Decimal]: defaultDecimal,
    [NumberFormatKey.VariableDecimal]: defaultDataVariableDecimal,
    [NumberFormatKey.Currency]: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    }
  }
};
