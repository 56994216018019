






















// Libraries
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import reports from '@/store/reports';
// View Models
// Components

@Component({
  name: 'clear-selected-variables-modal'
})
export default class ClearSelectedVariablesModal extends Vue {
  // VUE.JS Props
  @Prop({ required: true })
  public show!: boolean;

  // Refs
  // VUEX
  // Properties
  public get showModal(): boolean {
    return this.show;
  }
  public set showModal(show: boolean) {
    this.submitEmit(show);
  }
  // Fields
  // Getters
  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // activated(): void {}
  // deactivated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public onCancel() {
    this.showModal = false;
  }
  public onConfirm() {
    reports.setSelectedVariableCount(0);
    this.showModal = true;
  }
  // Watchers
  // Emitters
  @Emit('submit')
  public submitEmit(confirmedEmit: boolean) {}
}
