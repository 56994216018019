import {
  IHierarchyTreeViewModel,
  IReportVariableNode,
  IReportAssetVariableViewModel,
  ReportVariableNode, IAssignmentTreeNodeViewModel
} from '@/view-models/report-variables-tree/report-variable-node';
import '@/shared/extensions/array.extensions.ts';
import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { IFetchNodeLeavesPayload, IReportVariablesTreeModule } from './types/report-variables-tree';
import store from '.';
import { AssetHierarchyService } from '@/services/asset-hierarchy-service';
import { AssignmentTreeService } from '@/services/assignment-tree-service';
import { ReportVariableService } from '@/services/report-variable-service';
import { AssignmentNodeType } from '@/enums/assignment-node-type';
import { alphabeticSorter } from '@/shared/array-utils';
import { debounce } from '@/shared/date-time-utils';

@Module({ dynamic: true, store, name: 'reports-variables-tree' })
export class ReportVariablesTreeModule extends VuexModule implements IReportVariablesTreeModule {
  public loadingReportVariableDataNodeLeaves: Array<string> = [];
  public rootNodeKey: string = null;
  public flattenedVariableNodes: IReportVariableNode[] = [];
  public variableNodeMap: Map<string, IReportVariableNode> = new Map<string, IReportVariableNode>();
  public searchedNodeKeys: string[] = [];
  public reportVariablesTreesLoaded: boolean = false;
  public sites: IAssignmentTreeNodeViewModel[] = [];
  public assets: IAssignmentTreeNodeViewModel[] = [];
  public selectedSite: IAssignmentTreeNodeViewModel = null;
  public selectedAsset: IAssignmentTreeNodeViewModel = null;

  private loadingTrees: number = 0;

  // Getters
  public get doesContainSearchedNode(): (node: IReportVariableNode) => boolean {
    return (node: IReportVariableNode) => {
      if (this.searchedNodeKeys?.length > 0) {
        return recursiveDoesContainKeys(node.key, this.searchedNodeKeys, this.variableNodeMap);
      }
      return true;
    };
  }

  public get isLoadingReportVariablesTrees(): boolean {
    return this.loadingTrees > 0;
  }

  public get isLoadingReportVariableDataNodeLeaves(): (nodeKey: string) => boolean {
    return (nodeKey: string) => {
      const found = this.loadingReportVariableDataNodeLeaves.find((key) => key === nodeKey);
      return found !== undefined;
    };
  }

  public get isSearchedNodeInclusive(): (node: IReportVariableNode) => boolean {
    return (node: IReportVariableNode) => {
      return this.searchedNodeKeys?.indexOf(node.key) > -1;
    };
  }

  @Mutation
  public setLoadingReportVariablesTrees(loading: boolean): void {
    this.loadingTrees += loading ? 1 : -1;
  }

  @Mutation
  public setReportVariablesTreesLoaded(loaded: boolean): void {
    this.reportVariablesTreesLoaded = loaded;
  }

  @Mutation
  public setLoadingReportVariableDataNodeLeaves(data: { nodeKey: string; loading: boolean }): void {
    if (data.loading) {
      this.loadingReportVariableDataNodeLeaves.push(data.nodeKey);
    } else {
      this.loadingReportVariableDataNodeLeaves = this.loadingReportVariableDataNodeLeaves.filter(
        (nodeKey) => nodeKey !== data.nodeKey
      );
    }
  }

  @Mutation
  public setRootNode(node: IReportVariableNode): void {
    if (node) {
      this.rootNodeKey = node.key;
      this.flattenedVariableNodes = [node];
    } else {
      this.rootNodeKey = '';
      this.flattenedVariableNodes = [];
    }
  }

  @Mutation
  public setReportVariableNodeChild(child: IReportVariableNode): void {
    const parentReportVariableNode: IReportVariableNode = this.variableNodeMap.get((child.parentKey));

    if (parentReportVariableNode) {
      child.topDownHierarchyNames.push(...parentReportVariableNode.topDownHierarchyNames, child.name);
      parentReportVariableNode.children.push(child.key);
      this.flattenedVariableNodes.push(child);
      this.variableNodeMap.set(child.key, child);
    }
  }

  @Mutation
  public setReportVariableNodeChildren(data: { parentNodeKey: string; children: IReportVariableNode[] }): void {
    const parentReportVariableNode = this.variableNodeMap.get(data.parentNodeKey);

    if (parentReportVariableNode) {
      data.children.forEach((child) => {
        child.topDownHierarchyNames.push(...parentReportVariableNode.topDownHierarchyNames, child.name);
      });

      parentReportVariableNode.children = [...data.children.map((child) => child.key)];
      this.flattenedVariableNodes.push(...data.children);
      data.children.forEach((child) => this.variableNodeMap.set(child.key, child));
    }
  }

  @Mutation
  public setSearchedNodeKeys(keys: string[]): void {
    this.searchedNodeKeys = keys;
  }

  @Mutation
  public setSites(assignments: IAssignmentTreeNodeViewModel[]): void {
    const sites = assignments.filter((assignment) =>
        assignment.type === AssignmentNodeType.Site);
    sites.sort(alphabeticSorter<IAssignmentTreeNodeViewModel>((node) => node.name));
    this.sites = sites;
    if (!this.selectedSite || !sites.find((site) => site.key === this.selectedSite.key)) {
      this.selectedSite = sites[0] ?? null;
      this.selectedAsset = null;
    }
  }
  @Mutation
  public setAssets(assignments: IAssignmentTreeNodeViewModel[]): void {
    const assets = assignments.filter((assignment) =>
        // Since we consolidated the assignmentTree endpoints, some discrepancies exist
        // modify the check here to account for that
        (assignment.type === AssignmentNodeType.Asset) ||
        (assignment.type === AssignmentNodeType.Heater) ||
        (assignment.type === AssignmentNodeType.Tower));
    this.assets = assets;
    if (this.selectedAsset && !assets.find((asset) => asset.key === this.selectedAsset.key)) {
      this.selectedAsset = null;
    }
  }
  @Mutation
  public setSelectedSite(site: IAssignmentTreeNodeViewModel): void {
    this.selectedSite = site;
  }
  @Mutation
  public setSelectedAsset(asset: IAssignmentTreeNodeViewModel): void {
    this.selectedAsset = asset;
  }

  @Mutation
  public closeAllNodes(): void {
    this.flattenedVariableNodes.forEach((node) => node.isOpen = false);
  }

  @Mutation
  public openParentNodes(nodeKey: string): void {
    let currentVariableNode: IReportVariableNode = this.variableNodeMap.get(nodeKey);
    let parentNode: IReportVariableNode;
    while (currentVariableNode != null) {
      parentNode = this.variableNodeMap.get(currentVariableNode.parentKey);
      if (parentNode) {
        parentNode.isOpen = true;
      }
      currentVariableNode = parentNode;
    }
  }

  @Mutation
  public toggleOpenNode(currentNodeKey: string): void {
    const currentVariableNode: IReportVariableNode = this.variableNodeMap.get(currentNodeKey);
    if (currentVariableNode) {
      currentVariableNode.isOpen = !currentVariableNode.isOpen;
      if (!currentVariableNode.isOpen) {
        currentVariableNode.children.forEach((child) => recursiveCloseNodes(child, this.variableNodeMap));
      }
    }
  }


  // Actions
  @Action({ rawError: true })
  public async fetchReportVariablesTree(): Promise<void> {
    this.setRootNode(null);
    if (this.selectedAsset == null) {
      return;
    }
    this.setLoadingReportVariablesTrees(true);
    debounce('fetch-tree-debounce', 300, async () => {
      const rootNode: IReportVariableNode = {
        key: this.selectedAsset.key,
        name: this.selectedAsset.name,
        topDownHierarchyNames: [],
        displayName: this.selectedAsset.name,
        assetKey: this.selectedAsset.key,
        parentKey: null,
        customerSiteKey: this.selectedAsset.customerSiteKey,
        isLeaf: false,
        isOpen: false,
        children: []
      };

      this.setRootNode(rootNode);

      this.variableNodeMap.set(rootNode.key, rootNode);

      const assetHierarchyService = AssetHierarchyService.factory();

      const hierarchyTrees = (await assetHierarchyService.getAssetHierarchyTrees(rootNode.key)) ?? [];
      const lowerLevelNodes: IReportVariableNode[] = [];

      const hierarchyName: string = this.selectedSite.name + '/' + this.selectedAsset.name;

      hierarchyTrees.forEach((hierarchyTree: IHierarchyTreeViewModel) => {
        // Include all the parent hierarchy tree nodes except ones for asset level
        // Asset level node has already been added by the assignment tree above
        if (hierarchyTree.key !== rootNode.key) {
          const reportVariableNode: IReportVariableNode = new ReportVariableNode({
            key: hierarchyTree.key,
            name: hierarchyTree.name,
            topDownHierarchyNames: [],
            displayName: hierarchyTree.name,
            assetKey: hierarchyTree.assetKey,
            parentKey: hierarchyTree.parentKey,
            customerSiteKey: rootNode.customerSiteKey,
            isLeaf: false,
            isOpen: false,
            children: []
          });

          this.setReportVariableNodeChild(reportVariableNode);
        }

        const nodes = hierarchyTree?.availableNodes ?? [];
        for (const node of nodes) {
          const reportVariableNode = new ReportVariableNode({
            key: `${node.nodeKey}-data-node`,
            dataNodeKey: node.nodeKey,
            name: node.nodeName,
            topDownHierarchyNames: [hierarchyName],
            displayName: node.nodeName,
            assetKey: node.assetKey,
            parentKey: node.parentNodeKey,
            customerSiteKey: rootNode.customerSiteKey,
            isLeaf: false,
            isOpen: false,
            isDataNode: true,
            children: [],
          });

          this.setReportVariableNodeChild(reportVariableNode);

          lowerLevelNodes.push(reportVariableNode);
        }
      });

      await this.fetchAssetVariablesNodeLeaves({
        assetNode: rootNode,
        dataNodes: lowerLevelNodes,
      });

      this.setLoadingReportVariablesTrees(false);
      this.setReportVariablesTreesLoaded(true);
    });
  }

  @Action({ rawError: true })
  public async fetchAssetVariablesNodeLeaves(payload: IFetchNodeLeavesPayload): Promise<void> {
    payload.dataNodes.forEach((dataNode) => {
      this.setLoadingReportVariableDataNodeLeaves({
        nodeKey: dataNode.key,
        loading: true,
      });
    });

    try {
      const variables = await ReportVariableService.factory().getAssetVariables(payload.assetNode.assetKey);

      const reportVariableNodeChildren = variables.map(
        (variable: IReportAssetVariableViewModel) =>
          new ReportVariableNode({
            key: variable.variableKey,
            dataNodeKey: variable.nodeKey,
            name: variable.displayName,
            topDownHierarchyNames: [],
            displayName: variable.displayName,
            assetKey: payload.assetNode.assetKey,
            parentKey: `${variable.nodeKey}-data-node`,
            customerSiteKey: '',
            isLeaf: true,
            containsReportData: variable.hasData,
            dataRefs: variable.dataRefs,
            reportTableKey: variable.reportTableKey,
            measurementType: variable.measurementType,
          })
      );

      const reportVariableNodeChildrenGroup = reportVariableNodeChildren.groupBy('dataNodeKey');
      reportVariableNodeChildrenGroup.forEach((result: IReportVariableNode[], dataNodeKey: string) => {
        const parentDataNodeKey = `${dataNodeKey}-data-node`;

        this.setReportVariableNodeChildren({
          parentNodeKey: parentDataNodeKey,
          children: result,
        });
      });
    } catch (error) {
      throw error;
    } finally {
      payload.dataNodes.forEach((dataNode) => {
        this.setLoadingReportVariableDataNodeLeaves({
          nodeKey: dataNode.key,
          loading: false,
        });
      });
    }
  }

  @Action({ rawError: true })
  public async fetchAssignmentTree(): Promise<void> {
    const assignmentTree: IAssignmentTreeNodeViewModel[] = await AssignmentTreeService.factory().getAssignmentTree();
    this.setSites(assignmentTree);
    this.setAssets(assignmentTree);
    if (!this.selectedSite || !this.selectedAsset) {
      this.setRootNode(null);
    }
  }
}

export default getModule(ReportVariablesTreeModule, store);

function recursiveCloseNodes(key: string, nodes: Map<string, IReportVariableNode>): void {
  const node = nodes.get(key);
  if (node) {
    node.isOpen = false;
    // Data node means all children are leaves and don't need to be closed.
    if (!node.isDataNode) {
      node.children.forEach((child) => recursiveCloseNodes(child, nodes));
    }
  }
}

function recursiveDoesContainKeys(key: string, keys: string[], nodes: Map<string, IReportVariableNode>): boolean {
  if (keys.indexOf(key) > -1) {
    return true;
  }
  const node = nodes.get(key);
  if (node) {
    for (const child of node.children) {
      if (recursiveDoesContainKeys(child, keys, nodes)) {
        return true;
      }
    }
  }
}
