import { render, staticRenderFns } from "./Breadcrumb.vue?vue&type=template&id=45b0c218&scoped=true&"
import script from "./Breadcrumb.vue?vue&type=script&lang=ts&"
export * from "./Breadcrumb.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "45b0c218",
  null
  ,true
)

export default component.exports