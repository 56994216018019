






































// Libraries
import { Component, Vue } from 'vue-property-decorator';
import { elementId } from '@/shared/component-utils';
// View Models
import { eventBus } from '@/shared/event-bus';
// Components
import SelectDropdown from '@/components/shared/SelectDropdown.vue';
// Stores
import reports from '@/store/reports';
import { IReportViewModel } from '@/view-models/reports';

@Component({
  name: 'delete-modal',
  components: { SelectDropdown }
})
export default class DeleteModal extends Vue {
  // VUE.JS Props
  // VUEX
  // Properties
  public showModal: boolean = false;
  public report: IReportViewModel = null;
  public id: string = 'delete-modal';
  // Fields
  // Getters

  public elementId: (
    entityType: string,
    propertyOrActionOrInputName: string,
    entityKey?: string
  ) => string = elementId;
  // Lifecycle Handlers
  // beforeCreate(): void {}
  public created(): void {
     eventBus.$on('delete-report', (data: IReportViewModel) => {
      this.report = data;
    });
  }
  // beforeMount(): void {}
  // private mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  // Event Methods
  public async handleDelete(reportKey: string) {
    await reports.removeReport(reportKey).finally(()=> {
      this.showModal = false;
    });
  }
  // Watchers
  // Emitters
}
