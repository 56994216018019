
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';
import store from '.';

export interface IAppModule {
  // State
  isLoading: boolean;
  isAppInitialized: boolean;
  activeCustomerKey: string;
  // Getters
  // Mutations
  setIsLoading(value: boolean): void;
  setIsAppInitialized(value: boolean): void;
  setActiveCustomerKey(key: string): void;
  // Actions
}

@Module({ dynamic: true, store, name: 'app'})
export class AppModule extends VuexModule implements IAppModule {
  // State
  public isLoading: boolean = true;
  public isAppInitialized: boolean = false;
  public activeCustomerKey: string = '';

  // Getters
  // Mutations
  @Mutation
  public setIsLoading(value: boolean): void {
    this.isLoading = value;
  }
  @Mutation
  public setIsAppInitialized(value: boolean): void {
    this.isAppInitialized = this.isAppInitialized || value;
  }
  @Mutation
  public setActiveCustomerKey(key: string): void {
    this.activeCustomerKey = key;
  }

  // Actions
}

let module: AppModule = getModule(AppModule, store);
export const setAppModule = (newModule: AppModule) => (module = newModule);
export default module;
