






































































































































// Libraries
import { Vue, Component } from 'vue-property-decorator';
import { elementId } from '@/shared/component-utils';
import Fuse from 'fuse.js';
import { Nil } from '@/shared/types';
import { isStringEmpty } from '@/shared/string-utils';
import { getDateTime } from '@/shared/date-time-utils';
import {
  alphabeticSorter,
  alphabeticSorterDesc,
  ObjectSorterType,
  dateSorter,
  dateSorterDesc,
} from '@/shared/array-utils';
// View Models
import { IReport, IReportViewModel } from '@/view-models/reports';
import { SortOrderEnum } from '@/enums/report-enums';
import { IPagedRequest } from '@/view-models/table';
// Components
import Loading from '@/components/common/Loading.vue';
import IconButton from '@/components/dsm/IconButton.vue';
import ReportCloneModal from '@/components/modals/ReportCloneModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import { eventBus } from '@/shared/event-bus';
// Services
// Stores
import reports from '@/store/reports';
import reportVariablesTree from '@/store/report-variables-tree';
import ObjectSearch from '@/components/shared/ObjectSearch.vue';

@Component({
  name: 'reports-table',
  components: {
    ObjectSearch,
    Loading,
    IconButton,
    ReportCloneModal,
    DeleteModal,
  },
})
export default class ReportsTable extends Vue {
  // VUE.JS Props
  public elementId: (entityType: string, propertyOrActionOrInputName: string, entityKey?: string) => string = elementId;
  // VUEX
  public get reports(): IReport[] {
    return reports.reportsList ?? [];
  }
  public get loadingReports() {
    return reports.loadingReports || reportVariablesTree.isLoadingReportVariablesTrees;
  }

  // Properties
  public SortOrderEnum = SortOrderEnum;
  public showModal: boolean = false;
  public pagedOptions: IPagedRequest<IReport> = {
    page: 1,
    perPage: 50,
    sort: { column: 'modifiedAt', ascending: false },
  };
  public objectSearchKey = 1;
  public showEmptyResults: boolean = false;
  public searchedResult: IReport[] = null;

  // Fields
  private cloneReport: Nil<IReport> = null;

  // Getters
  public get filteredReports(): IReport[] {
    return this.searchedResult ?? this.reports;
  }

  public get hasData(): boolean {
    return this.reports.length > 0 && !this.loadingReports;
  }

  public get noDataText() {
    return this.$t('generic.noItemAvailable', { name: this.$t('generic.reports') });
  }

  public get searchTerm(): Nil<string> {
    return this.pagedOptions.searchTerm;
  }
  public set searchTerm(value: Nil<string>) {
    const searchTerm = isStringEmpty(value) ? undefined : value;
    this.modifyPagedOptions({
      page: 1,
      searchTerm
    });
  }
  public get searchName(): string {
    return this.$t('generic.report')
      .toString()
      .toLowerCase();
  }
  public get isNameAscending(): Nil<boolean> {
    return this.pagedOptions.sort?.column === 'name' ? this.pagedOptions.sort.ascending : undefined;
  }
  public get isLastModifiedAscending(): Nil<boolean> {
    return this.pagedOptions.sort?.column === 'modifiedAt' ? this.pagedOptions.sort.ascending : undefined;
  }
  public get totalPages(): number {
    return Math.ceil(this.filteredReports.length / this.pagedOptions.perPage);
  }
  public get hasPrevPages(): boolean {
    return this.totalPages > 3 && this.pagedOptions.page >= 3;
  }
  public get hasNextPages(): boolean {
    return this.totalPages > 3 && this.pagedOptions.page <= this.totalPages - 2;
  }
  public get availablePages(): number[] {
    let arr: number[];
    let mapper: (v: number, i: number) => number;
    if (this.totalPages > 3) {
      const pageNumber = this.pagedOptions.page;
      const pageJump = pageNumber > 1
          ? pageNumber === this.totalPages ? pageNumber - 2 : pageNumber - 1
          : 1;
      arr = new Array(3);
      mapper = (x, i) => i + pageJump;
    } else {
      arr = new Array(this.totalPages);
      mapper = (x, i) => i + 1;
    }
    return Array.from(arr, mapper);
  }
  public get sortedReports(): IReport[] {
    const sortColumn = this.pagedOptions.sort.column;
    if (!isStringEmpty(sortColumn)) {
      switch (sortColumn) {
        case 'name': {
          const sorter: ObjectSorterType<IReport, string> = this.pagedOptions.sort.ascending
              ? alphabeticSorter
              : alphabeticSorterDesc;
          return this.filteredReports?.sort(sorter((r) => r.name));
        }
        case 'modifiedAt': {
          const sorter: ObjectSorterType<IReport, Date | string> = this.pagedOptions.sort.ascending
              ? dateSorter
              : dateSorterDesc;
          return this.filteredReports?.sort(sorter((r) => r.modifiedAt));
        }
      }
    }
  }
  public get pagedReports(): IReport[] {
    const start = Math.max((this.pagedOptions.page - 1) * this.pagedOptions.perPage, 0);
    const end = start + this.pagedOptions.perPage;
    return this.sortedReports.slice(start, end);
  }

  // Lifecycle Handlers
  // beforeCreate(): void {}
  // created(): void {}
  // beforeMount(): void {}
  // mounted(): void {}
  // beforeUpdate(): void {}
  // updated(): void {}
  // beforeDestroy(): void {}
  // destroyed(): void {}
  // Private Methods
  // Helper Methods
  public displayLastModified(value: string) {
    return getDateTime(value);
  }

  public createNewReport() {
    reports.resetReport();
    this.$router.push('/settings');
  }

  public sortBy(column: keyof IReport) {
    const ascending = this.pagedOptions.sort?.column === column ? !this.pagedOptions.sort.ascending : true;
    this.modifyPagedOptions({
      page: 1,
      sort: {
        column,
        ascending,
      },
    });
  }
  private modifyPagedOptions(update: Partial<IPagedRequest<IReport>>) {
    this.pagedOptions = Object.assign({}, this.pagedOptions, update);
  }

  private showDeleteModal(report: IReportViewModel): void {
    const modal = this.$refs.DeleteModal as DeleteModal;
    modal.showModal = true;
    eventBus.$emit('delete-report', report);
  }

  // Event Methods
  public handleFave(reportKey: string) {
    const found = this.reports.find((report) => report.key === reportKey);
    if (found != null) {
      if (found.isFavorite) {
        reports.unfaveReport(reportKey);
      } else {
        reports.faveReport(reportKey);
      }
    }
  }
  public handleDuplicate(reportKey: string) {
    this.cloneReport = this.reports.find((report) => report.key === reportKey);
  }
  public handleDuplicateModalClose() {
    this.cloneReport = null;
  }
  public goToPage(page: number): void {
    this.modifyPagedOptions({ page });
  }
  public prevPage(): void {
    if (this.pagedOptions.page <= 1) {
      return;
    }

    const page = this.pagedOptions.page - 1;

    this.goToPage(page);
  }
  public nextPage(): void {
    if (this.pagedOptions.page >= this.totalPages) {
      return;
    }

    const page = this.pagedOptions.page + 1;

    this.goToPage(page);
  }
  public clearSearch() {
    this.pagedOptions.searchTerm = '';
  }

  public get results(): any[] {
    return this.reports;
  }

  public set results(values: any[]) {
    this.searchedResult = values ? [...values] as IReport[] : null;
  }

  // Watchers
  // Emitters
}
